.theme-v-tabs {
	display: flex;
	align-items: flex-start;
  }
  
  .theme-v-tabs ul {
	min-width: 170px;
	display: flex;
	padding: 0px;
	flex-direction: column;
	justify-content: flex-start;
	list-style-type: none !important;
	margin: 0px !important;
	background: transparent;
	flex-grow: 1;
  }
  
  .theme-v-tabs ul > li {
	margin: 0px !important;
	flex: 1;
	display: flex;
	
	flex-direction: column;
  }
  
  .theme-v-tabs ul > li > p,
  .theme-v-tabs ul > li > .theme-vtab-tab {
	padding: 15px 15px;
	flex: 1;
	text-align: left;
	cursor: initial;
  }
  
  .theme-v-tabs ul > li > .theme-vtab-tab {
	border: none;
	outline: none;
	background-color: #F0F1F2;
  }
  
  .theme-v-tabs .theme-vtab-content {
	flex: 1;
	padding: 25px 1rem;
	background-color: #FFFFFF;
  }
  
  // Extra small devices (portrait phones, less than 576px)
  // No media query since this is the default in Bootstrap
  
  // Small devices (landscape phones, 576px and up)
  @media (max-width: 650px) {
	.theme-v-tabs {
		display: flex !important;
    flex-direction: column !important;
	background-color: none !important;
	  }
	  .theme-v-tabs ul {
		width: 100% !important;
		display: flex;
		flex-direction: row !important;
	  }
	  .theme-vtab-content  {
		  width: 100% !important;
	  }
	  .field-fr {
		  width: 100% !important;
	  }
  }
  
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
	
  }
  
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
	.theme-v-tabs {
	    padding: 0 1rem;
	    background-color: transparent;
	}
  
	.theme-v-tabs ul {
	    background: #F0F1F2;
	    flex-grow: 0;
	}
  
	.theme-v-tabs ul > li:first-child {
	    margin-top: 5px !important;
	}
  
	.theme-v-tabs ul > li:last-of-type {
	    margin-bottom: 5px !important;
	}
  
	.theme-v-tabs ul > li > .theme-vtab-tab {
	    background-color: #F0F1F2;
	    margin-left: 10px;
	    cursor: pointer;
	}
  
	.theme-v-tabs ul > li > .theme-vtab-tab.active {
	    background: #FFFFFF;
	}
	
	.theme-v-tabs ul > li > .theme-vtab-tab:hover {
	    background: #ffffff75;
	}
  
	.theme-v-tabs .theme-vtab-content {
	    padding: 25px 30px;
	    padding-top: 0px;
	    background-color: transparent;
	}
  }
  
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
  
  }
  