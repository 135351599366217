ol.progtrckr {
    margin: 0;
    padding: 0;
    // list-style-type none;
}
.trackorder {
    display: flex;
    justify-content: space-between;
}

ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 3.5em;
}

.merchantname-fr {
    font-size: 1rem !important;
    margin-top: 41px !important;
}

.itemimg-fr {
    width: 81px;
    height: 81px;
    border-radius: 6px !important;
    margin-top: 10px !important;
}
.straw-fr {
    font-size: 16px !important;
    padding-top: 10px;
}
.etatext-track {
    font-size: 16px !important;
}


ol.progtrckr[data-progtrckr-steps="2"] li { width: 49%; }
ol.progtrckr[data-progtrckr-steps="3"] li { width: 33%; }
ol.progtrckr[data-progtrckr-steps="4"] li { width: 24%; }
ol.progtrckr[data-progtrckr-steps="5"] li { width: 19%; }
ol.progtrckr[data-progtrckr-steps="6"] li { width: 16%; }
ol.progtrckr[data-progtrckr-steps="7"] li { width: 14%; }
ol.progtrckr[data-progtrckr-steps="8"] li { width: 12%; }
ol.progtrckr[data-progtrckr-steps="9"] li { width: 11%; }

ol.progtrckr li.progtrckr-done {
    color: #553A28;
    border-bottom: 4px solid #00A56D;
}
ol.progtrckr li.progtrckr-todo {
    color: silver; 
    border-bottom: 4px solid silver;
}

ol.progtrckr li:after {
    content: "\00a0\00a0";
}
ol.progtrckr li:before {
    position: relative;
    // bottom: -2.5em;
    // bottom: -11.1em;
      bottom: -8.5em;
    float: left;
    left: 50%;
    line-height: 1em;
}
.statusimg {
    position:relative;
    // bottom: -4em;
    bottom: -3em;
}
.foodstatusmodes-details {
    // bottom: -5em !important;
    bottom: -3em !important;
    position: relative;
    text-align: center !important;
    width: 200px;
}
.foodstatus {
    bottom: -8.1em;
    position: relative;
    text-align: center !important;
    margin-bottom: 20px !important;
    display: none;
}
.fr-active {
    margin-bottom: 10px !important;
}
.foodstatus-fr {
    // bottom: -8.1em;
    position: relative;
    text-align: start !important;
    margin-bottom: 20px !important;
    margin-top: 41px !important;
    margin-left: 20px;
}
ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #00A56D;
    height: 2.2em;
    width: 2.2em;
    line-height: 2.2em;
    border: none;
    border-radius: 2.2em;
}
ol.progtrckr li.progtrckr-todo:before {
    content: "\2713";
    color: white;
    background-color: silver;
    height: 2.2em;
    width: 2.2em;
    line-height: 2.2em;
    border: none;
    border-radius: 2.2em;
}
.tr-head-title{
    color: #553A28;
    font-size: 18px;
    padding-bottom: 10px !important;
}
.trackmapdetails {
    display: flex;
    justify-content: flex-end;
}
.trackmodalheadertext {
    color:#E5AB4E;
    font-size: 15px !important;
    padding-bottom: 10px;
}
.trackmodalcontent {
    width: 223px;
    font-size: 14px !important;
    text-align: start;
    margin-bottom: 10px !important;
}
.trackmodaltext {
    color: #2C2C2C !important;
    font-size: 14px !important;
}
.trackhelp-button {
    border: 0;
    background: #fff;
    color:#E5AB4E  !important;
    font-size: 14px !important;
    text-transform: uppercase;
}
.trackheader {
    font-size: 20px !important;
    color:#553A28;
    text-transform: none !important;
}
.modalHeading-track {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    margin-bottom: 20px;
    margin-top: 20px;
}
.greencircle {
    font-size: 14px;
    padding:14px;
    width: 14px;
    height: 14px;
    border: 7px;
    background: #75CB1D !important;
}
.trackmapstatus {
    border-radius: 27px 27px 0px 0px;
}
@media screen and (max-width: 768px) {
ol.progtrckr li.progtrckr-done {
    border-bottom: none !important;
    border-left: 4px solid #00A56D !important;
    display: flex !important;
    line-height: inherit;
    padding-bottom: 30px;
}
ol.progtrckr li.progtrckr-todo{
    border-bottom: none !important;
    border-left: 4px solid silver;
    display: flex !important;
    line-height: inherit;
    padding-bottom: 35px;
}
ol.progtrckr[data-progtrckr-steps="4"] li{
    width: auto;
}
ol.progtrckr li:before{
    bottom: -0.5em !important;
    left: -1.1rem !important;
}
ol.progtrckr li:before {
    position: relative;
    bottom: -2.5em;
    // bottom: -11.1em;
    float: left;
    left: 50%;
    line-height: 1em;
}
.foodstatus-mobile {
    position: absolute;
    text-align: left;
    // left: 300px;
    width: 250px;
}
}
@media screen and (max-width: 450px) {
    .foodstatus-mobile {
        position: absolute;
        left: 119px !important;
        margin-top: 20px;
    }
}
@media screen and (max-width: 375px) {
    .foodstatus-mobile {
        position: absolute;
        left: 103px !important;
        margin-top: 20px;
    }
}