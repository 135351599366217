body {
    /* font-size: 10px; */
}
.loyaltyrow1 {
    background-color:#5D1F00EA;
    
}

.tier-grid{
    display: flex; 
    flex-direction: row;
     align-items: center;
     justify-content: space-between;
}

.signup-loyalty-grid{
    display: flex; 
    flex-direction: row;
     align-items: flex-start;
     min-height: 300px;
     padding: 40px 0px;
     justify-content: space-evenly;
     margin:70px 0px 70px 0px;
     background-color: #FFFFFF;
}

.signup-loyalty-card-two{
    display: flex; flex-direction: column;
    align-items: flex-start; 
     width: 400px
}

.tier-card{
    display: flex; flex-direction: column; align-items: flex-start;
    width: 384px;height: 592px;
     border-radius: 6px; margin: 13px; padding: 10px; color: #FFFFFF;
}

.refer-earn{
    display: flex; flex-direction: row; align-items: inherit; justify-content: center;
}

.rewards-boutique{
    display: flex ;
    flex-direction: row;
     align-items: center; justify-content: space-between;
      height: 560px;
       margin: 90px 0px 90px 0px;
        background-color: #FFFFFF
}

.refer-earn-card{
    display: flex; flex-direction: column; align-items: flex-start;
    width: 551px;  border-radius: 6px; 
    border: 1px solid #B2BAC3;
    margin: 13px; padding: 30px; background-color: #FFFFFF
}

.reg-fields{
    margin:10px;
}

@media only screen and (max-width: 768px) {
    
    .tier-grid{
        flex-direction: column;
    }
    .refer-earn{
       flex-direction: column;
    }

    .refer-earn-card{
      
        width: 90%;  
      
    }

    .rewards-boutique{
        flex-direction: column;
        /* margin-bottom: 400px; */
          height: 900px;
    }

    .tier-card{
        width: 85%;
        margin-bottom: 13px;
    }

    .signup-loyalty-grid{
      align-items: center;
        flex-direction:  column-reverse;
        justify-content: space-evenly;
       height: 700px;
    }
    .signup-loyalty-card-two{
      margin:0px 30px 20px 30px;
         width: auto
    }
    .fr-flex-column-center{
      margin-bottom:30px;
  }
}

.wrapper {
    position:relative;
    /* margin:20px; */
}
.arc {
    position:absolute;
    top:-17px;
    left:-17px;
    width:60px;
    height:60px;
    border-radius:100%;
    border:3px solid;
}
.arc_start {
    border-color:transparent red red red;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(-45deg);
}

/* ul:nth-of-type(1) {
    list-style-type: disc;
  } */
   
.loyaltyrow1 img {
    width: 512px !important;
    height: 512px !important;
    bottom: 0px !important;
    opacity: 0.06;
}
.loyaltycontent {
    float: right;
   text-align: right;
}

.loyaltyheadng2 {
   
    text-align: right;
width:950px;
margin:auto; 
text-align: right;
font-size:30px;
font-weight: bold;
font-family: 'Helvetica', 'Arial', sans-serif !important;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.loyaltyheadng {
    padding-top:150px !important;
    font-size: 70px !important;
    font-family: 'Helvetica', 'Arial', sans-serif !important;
    font-weight: bold;
    letter-spacing: 0px !important;
    color: #FFFFFF !important;
    opacity: 1;
}
.loyaltycontent button {
    background-color: #FF9D00;
    padding-top:20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
font: normal normal bold 40px/48px Helvetica Neue;
letter-spacing: 0px;
color: #6E2602;
opacity:1;
border:none;
}
.loyaltyrow2 {
max-height: 150px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
display: flex;
text-align: center;
position: absolute;
width: 100% !important;
   margin:auto;
    left:0px;
    right:0px;


}
.loyaltyrow2 img {
    width:89px !important;
    height:89px !important;
    margin:auto;
}
.loyaltyrow2 p{
    text-align: center;
letter-spacing: 0px;
font-size: 30px !important;
font-weight: bold !important;
font-family: 'Helvetica', 'Arial', sans-serif !important;
color: #6E2602;
opacity: 1;
}
.loyaltyrow3 {
    padding-bottom:80px;
    padding-top:90px;
    position: relative;

}
.loyaltyrow3 img {
    width:199px;
    height: 199px;
}
.loyaltyrow3 p {
    text-align: center;
font: normal normal bold 40px Helvetica Neue;
letter-spacing: 0px;
color: #6E2602;
opacity: 1;
}
.loyal1 {
   text-align: center;
}
.loyal1 p {
    font-size:38px;
    font-family: 'Helvetica', 'Arial', sans-serif !important ;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0px;
    color: #6E2602;
    opacity: 1;
    padding-top:23px;
}
.loyal4 {
    background-color: #6E2600;
}

.loyal4 h3 {
    font-size:50px;
    font-weight: bold;
    font-family: 'Helvetica', 'Arial', sans-serif !important ;
    padding-top:58px !important;
    text-align: center;
color: #FFFFFF !important;
opacity: 1;
padding-bottom:90px;
}
.loyal4column {
    text-align:center;
    margin-bottom: 80px;
}
.loyal4col {
    
    justify-content: space-evenly !important;
}
.loyal4 strong {
    font-size:60px;
    font-weight: bold;
    font-family: 'Helvetica', 'Arial', sans-serif !important ;
    padding-top:58px !important;
    text-align: center;
    color: #FFFFFF !important;
    opacity: 1;
    padding-bottom:90px;
    padding-top: 10px !important;
    letter-spacing: 0px;
    }
    .loyal4column img {
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        opacity: 1;
        width:200px;
        height:200px;

    }
    .loyal4column p {
        text-align: center;
        font-size: 30px;
        font-family: 'Helvetica', 'Arial', sans-serif !important ;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        padding-top:24px
    }
  .loyal5 {
      text-align:center;
  }
    .loyaltyrow6 img {
        width: 292px;
        height: 292px;
        margin-top: 80px;
      
    }
    .loyal6 {
        text-align: start;
    }
    .loyal6 h2 {
        text-align: left;
        font-size: 60px;
        font-weight: bold;
        font-family: 'Helvetica', 'Arial', sans-serif !important ;
        letter-spacing: 0px !important;
        /* color: #383838; */
        opacity: 1;
        padding-top:120px;
    }
    .sectiontext h2 {
      color: #ffffff !important;
text-align: center;
    }
.loyalp {
font-size:30px;
font-family: 'Helvetica', 'Arial', sans-serif !important ;
text-align: left !important;
letter-spacing: 0px !important;
color: #383838 !important;
opacity: 1;
margin-right: 200px;
}

    .loyaltyrowfooter span {
        background: #FCEFE5 0% 0% no-repeat padding-box;
        border-radius: 20px;
        opacity: 1;
        padding-top:10px;
        padding-bottom:10px;
        padding-left:30px;
        padding-right: 30px;
        height:50px;
        text-align: left;
        letter-spacing: 0px;
        font-style: italic !important;
        color: #434343;
        opacity: 1;
        padding-top:10px;
        padding-bottom:10px;
        padding-left:30px;
        padding-right:30px;
        font-family: 'Helvetica', 'Arial', sans-serif;
    font-weight: bold;
    }

    .loyaltyrowfooter {
        display: flex !important;
    padding-left: 50px;
    margin-bottom: 100px !important;
    
    }
    .loyaltyrowfooter img {
        height:80px;
        vertical-align: middle;
    }

    .loyaltyrowfooter ul li{
        display:inline-flex;
    }
    .loyaltyrowfooter ul li a {
        padding:30px;
    }
    .loyaltyrowfooter ul li p {
        font-family: 'Helvetica', 'Arial', sans-serif;
    font-weight: bold;
        text-align: left;
    letter-spacing: 0px;
    color: #434343;
    font-style: italic !important;
    opacity: 1;
    }
    .loyaltyrowfooter ul li h3 {
        text-align: left;
        font-size:40px;
        font-family: 'Helvetica', 'Arial', sans-serif;
        font-weight: bold;
        letter-spacing: 0px;
        font-style: italic !important;
        color: #383838;
        opacity: 1;
    }
 .loyalbasefreebies {
    justify-content: space-evenly;
    display:flex;
 }   
 @media (min-width: 768)
{
    body
    {
        font-size: 15px !important;
    }
    h2,h3
    {
        font-size: 15px !important;
    }
    p {
        font-size:10px !important;
    }
    li {
        display: block;
    }
}


/* rk-start */
.fr-first-block {
    /* background-color: #5D1F00EA; */
    color: #FFFFFF;
    padding: 70px 100px;
}

.fr-first-bg{
    opacity: 0.06;
}

.fr-first-content{
text-align: end;
}

.fr-loyalty-btn{
    background-color: #FF9D00 !important;
    border-radius: 6px !important;
    border: none !important;
    color: #6E2602 !important;
    font-family: 'Lato' !important;
    padding: 0px 30px !important;
    margin-top: 20px !important;
    height: 40px;
}

.fr-second-content{
    color: #6E2602;
}
.fr-200x200{
    height: 200px;
    width: 200px;
}

.fr-base-freebie-image{
    border-radius: 6px;
    width: 170px;
    height: 170px;
    object-fit: cover;
}

.fr-140x140{
    height: auto;
    width: 140px;
}

.fr-80x80{
    height: auto;
    width: 80px;
}

.fr-second-block{
    /* padding: 100px; */

    background-color: #FFFFFF;
    position: relative;
}
.fr-flex-column-center{
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.fr-flex-row-center{
   display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.react-date-picker > .react-date-picker__wrapper{
    width: 100%;
    height: 50px;
    font-size: larger;
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    box-shadow: inset 0px 0px 5px 0px #f1f1f1;
}

.fr-absolute-block{
  align-items: center;
    position: absolute;
    top: -35px;
    width: calc(100% - 100px);
    background-color: #fff;
    margin: 0px 50px;
    box-shadow: 0px 3px 6px #00000029;
    color: #6E2602;
    border-radius: 8px;
    z-index: 2;
}
.fr-second-inner-block{
  display: flex;
  justify-content: space-around;
    padding:70px;
}
.fr-m-b-25{
    margin-bottom: 25px;
}
.fr-m-t-35{
    margin-top: 35px;
}

.fr-third-block{
    background-color:#FFFFFF;
    padding-top: 35px;
    padding-bottom: 35px;
    align-items: center;
}
.fr-terms-block{
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.terms-links > a{
color: #4a4a4a !important;
}

.fr-fourth-block{
    background-color: #fff;
    /* padding: 75px 0px; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.froogal-logo{
    height: 27px;
    width: auto;
}
.footer-hash-tag{
    background-color: #FCEFE5;
    padding: 10px;
    border-radius: 30px;
}

.fr-thick-vip{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px !important;
}

.fr-star-image{
  margin: 10px 20px 0px 20px;
        height: 40px !important;
        width: 40px !important;
    }

 @media (max-width: 756px) 
{
    .fr-first-block{
    padding: 50px !important;
    }

    .fr-thick-vip{
    text-align: center !important;
    }

    .fr-140x140{
        height: auto;
        width: 100px;
    }
    .fr-second-inner-block{
      display: flex;
      flex-direction: column;
        padding: 70px 0px !important;
    }
    .fr-fourth-block{
        flex-direction: column !important;
        padding:25px 0px;
    }
    .fr-copy-rights{
        margin-top: 25px;
    }


    .fr-vip-image{
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .fr-absolute-block{
        width:calc(100% - 50px) !important;
        margin:0px 25px !important;
        height: 100px;
    }
}

