.faqhead {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.faq h2 {
    text-align: center;
    color:#553A28;
    padding-left:40%;
    font-size: 24px;
    margin-top:41px;
    margin-bottom: 54px;

}
.faqcontent {
    color:#2C2C2C !important;
    font-size:16px;
    text-align: start;
}
.qusetion {
    color:#2C2C2C;
    font-size:18px;
    margin-bottom:18px;
    margin-left:22px;
}
.faqrow a {
    float:right;
    height: 26px;
    width:26px;
    color:#2C2C2C !important;
}
.fa-arrow-circle-left{
    width:50%;
    opacity: 60%;
    font-size: xx-large;
    margin-top:20px;
    margin-bottom: 54px;
}