.dir-root{
 	max-height:450px;
	/* align-items: inherit; */
	/* width: -webkit-fill-available; */
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width:thin;
	/* flex-wrap: nowrap; */
	scroll-behavior: smooth;
	border-bottom: 2px solid #f7f7f7d2;
	/* margin:5px; */
	/* background-color: rgba(192,192,192, 0.2); */
  }

  /* .dir-root::-webkit-scrollbar{
   display: none;
  } */

  .modalstore {
	  padding: 0px !important;
  }

  .phnnumber-store {
	  color:#543a20 !important;
	  padding-top: 10px;
	  padding-bottom: 10px;
  }
  .store-dropdown {
	width: 198px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px !important;
  }
  
  .fa-angle-right {
	  font-size: xx-large;
	  color:#543a20 !important;

  }
  .dir-maincard{
	  display: flex;
	flex: 0 0 auto;
		width: -webkit-fill-available;
cursor: pointer;
	flex-direction: row;
	justify-content: space-between;
	/* width: 37vw; */
	 height: '-webkit-fill-available';
	 padding: 10px;
	margin: 10px; 
	border-bottom: 2px solid #F7F7F7;
	/* background-color: rgba(192,192,192, 0.2);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px; */
  
  }
  @media only screen and (max-width: 450px) {
	.store-dropdown {
		width: 132px !important;
	}

  }