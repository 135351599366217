
.category {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 0 0 25px;
  margin-left:15px !important;
  margin-right:15px !important;
  margin-top:85px;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.category::-webkit-scrollbar {
  display: none;
}


/* ORDERMODE BUTTONS */
.categoryBox {
  justify-content: space-between;
  cursor: pointer;

  background: #fff;
  color: #553A28 ;
  /* box-shadow: 0 3px 4px 2px #ECEEF4C4; */
  /* box-shadow: 0px 0px 7px 3px  #ECEEF4C4; */
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1) ;

  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  margin: 20px 5px 20px 5px;
  border-radius: 4px;
  font-size: 20px;
  margin-bottom: 10px;
  min-width: 200px !important;
}
.categoryBox.active {
  background: #E5AB4E;
  color: #fff !important;  
}

.categoryBox.disabled {
  background: #00000034;
  color: #fff !important;  
}

.categoryBox img:first-child {
  width: 42px;
  /* margin-right: auto; */
}
.categoryBox img:last-child {
  width: 22px;
  margin-left: auto;
}
.categoryBox.active strong {
  color:#fff !important;
}



/* SEARCH INPUT */
.inputsearch { 
  background-image: url('../../images/Group\ 35380.svg');
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  padding:3px !important;
  border: none;
  background-color: #F0F1F2;
  border-radius: 8px;
  opacity: 1;
}

.dropdown-toggle::after {
  display:none;
}

.dropdown-search-menu{
  width: 550px;
  max-height: 500px;
  border-width: 0px;
  border-radius: 8px;
  padding: 0px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
  overflow-y: scroll; 
 }

.select-store-fr {
box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1) ;
  background-color: #fff !important;
  margin: 0 0 25px;
  padding: 15px;
  width: 312px;
  margin:20px;
  border-radius: 4px;
  display: none;
}


.sticky-menus{
  background-color: #FAFBFC;
   /* z-index: 5; */
   padding-top: 80px;
}

.exploreMenu {
  right: 20px;
  left: 20px;
  padding-top: 15px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-bottom: 10px;
  /* padding-bottom: 10px; */
   /* border-bottom: 1.5px solid #E7EAF0; */
}

.exploreMenu a {
  color: #2C2C2C;
  font-size: 17px;
  opacity: 0.5;
  padding: 6px 6px;
  text-decoration: none;
  white-space: nowrap;
}

.exploreMenu a.active {
  text-decoration: none;
  font-weight: 600;
  opacity: 1;
  color: #2C2C2C;
}

.exploreMenu a:hover {
  /* opacity: 0.5; */
  color: #2C2C2C;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 3px solid #E5AB4E;
}

.exploreMenu-fixed {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-bottom: 10px;
  padding-bottom: 10px;
   /* border-bottom: 1.5px solid #E7EAF0; */
 right: 0px;
 left: 20px;
  padding-top: 15px;
  flex-direction: column;
  margin-bottom: 5px;

    position:fixed;
  top: 0px;
  z-index: 5;
  background-color:#FAFBFC ;
  /* width:100vw; */
}

.exploreMenu-fixed a {
  color: #2C2C2C;
  font-size: 17px;
  opacity: 0.5;
  padding: 6px 6px;
  text-decoration: none;
  white-space: nowrap;
}

.exploreMenu-fixed a.active {
  text-decoration: none;
  font-weight: 600;
  opacity: 1;
  color: #2C2C2C;
}

.exploreMenu-fixed a:hover {
  /* opacity: 0.5; */
  color: #2C2C2C;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 3px solid #E5AB4E;
}



.veg-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 270px;
  height: 41px;
  color: black;
  border-width: 2px;
  border-radius: 6px !important;
  border-color: black;
  background-color: whitesmoke;
  font-size: 14px;
}


.search-root{
	display: flex;
	flex-direction: column;
	max-height: 350px;
	width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width:none;
	scroll-behavior: smooth;
  margin-top: 10px;
    box-shadow: 0 4px 10px 2px #ECEEF4C4;

}

.search-root::-webkit-scrollbar{
  display: none;
  }

  


.browsetext {
  float: left;
  text-align: left;
  font: normal normal 600 16px/22px Lato;
  letter-spacing: 0px;
  color: #2C2C2C;
   width: -webkit-fill-available;
}
.smallbrowsetext {
  text-align: left;
  font: normal normal normal 15px/22px Lato;
  letter-spacing: 0px;
  color: #2C2C2C;
  padding-right:30px;
}
.browsechange {
  text-align: right;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 1.4px;
  color: #2C2C2C;
  text-transform: uppercase;
  opacity: 1;
}


.openicon {
  width: 21px;
height: 21px;
font-size:21px;
opacity: 0.67;
}

.exploreItem {
  border-radius: 6px;
  box-shadow: 0 4px 10px 2px #ECEEF4C4;
  margin-top: 20px;

}
.exploreItem-fr {
  display: flex;
  /* max-width: 450px; */
  justify-content: space-between;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 4px 10px 2px #ECEEF4C4;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
}

.menuanimation {
  column-gap: 0em  !important; 
  column-count: 3 !important;
}

.explorecardmasonry {
  padding: 1em;
  margin-bottom: 1.3em;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}


@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.imgBox {
  border-radius: 6px 6px 0px 0px;

  max-height: 207px;
  overflow: hidden;
}
.imgBox img {
  width: 100%;
  height:270px;
  object-fit: cover;
}

.exploreContent {
  border-radius: 6px 6px 6px 6px;
  padding: 10px;
  font-size: 17px;
  background-color: #fff;
}

.itemDetails-exp {
  display: flex;
  }

button.button-fr {
  background-color: rgb(229, 171, 78) !important;
  border-color: rgb(229, 171, 78);
  border: none;
  color: #fff;
  font-size: 16px;
  
  
}
.button-fr-new {
  background-color: #fff !important;
  border-color: #553A20 !important;
  color: #553A20  !important;
  font-size: 14px;
  padding: 10px 100px !important;
  border-radius: 6px !important;
  letter-spacing: 1.4 !important;
  font-weight: bold;
  outline:auto !important
}
.view-cart-sticky-drawer-fr {
  background-color: #fff !important;
  justify-content: center !important;
}
button.button {
  background:#553A20;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 6px 34px;
  border-radius: 4px;
}
.sublayout {
  margin-left: 20px;
  margin-right:20px;
}

.name {
  color: #553A28;
  font-size: 16px;
  text-align: left;
  /* font-weight: 600; */
  /* margin-bottom: 0; */
}

.price {
  font-weight: 600;
  font-size: 18px;
}
.currency {
  margin-top: -3px;
  font-weight: normal;
  font-size: 14px;
  /* z-index: 0; */
}
.descriptoin {
  margin-top: 7px;
  font-size: 14px;
  text-align: start;
}
.stytitle {
  color:#2C2C2C !important;
}
.rec-root{
  display: flex;
  /* flex-direction: 'row'; */
  /* align-items: inherit; */
  width: -webkit-fill-available;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width:none;
  /* flex-wrap: nowrap; */
  scroll-behavior: smooth;
  /* background-color: rgba(192,192,192, 0.2); */
}
.text-capitalize{
margin-right: 15px !important;
}

.rec-root::-webkit-scrollbar{
display: none;
}

.rec-maincard{
  flex: 0 0 auto;
  /* width: 130px; */
  /* height: 190px;
  margin: 7px;
  background-color: rgba(192,192,192, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px; */

}
.nav-fr {
  text-align: left;
font: normal normal bold 16px/19px Lato;
letter-spacing: 0px;
color: #553A28 !important;
opacity: 1;
}
.navtext-fr {
  text-align: left;
font: normal normal normal 15px/19px Lato;
letter-spacing: 0px;
color: #B08A70 !important;
opacity: 1;
margin-bottom: 0px;
}
.address-fr {
  display:none;
}


.img-loading
 {  opacity: 0;
   width: 100%;
   height: auto;
   background-color: #00000034;
   }
.img-loaded{
   animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
   position: relative;
   opacity: 0;
   animation-fill-mode: forwards;
   animation-duration: 1.7s;
   animation-delay: 0s;
   }
  

   @keyframes fadeInImg {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }








 /* ********************************************
    MEDIA QUERIES
 ************************************************ */
 @media only screen and (max-width: 375px) {

  /* .inputsearch {
      width: 222px !important;
   } */
}

 @media screen and (max-width: 450px) {

  /* .inputsearch {
    width: 251px !important;
 } */
  .category {
    max-width: 991px !important;
  }

  }

@media only screen and (max-width: 600px) {
  .browsetext {
   display: none;
  }
  .select-store-fr {
    width:-webkit-fill-available !important;
    display: inherit;
  }

  .address-fr {
    display:inline;
  }
  
  .dropdown-search-menu{
    /* width: current screen width-100; */
    width: 380px;
 }
}

 @media only screen and (max-width: 768px) { 
  .sticky-menus{
    
     padding-top: 0px;
  }
 .menuanimation-fr {
  column-gap: 0em  !important; 
  column-count: 2 !important;
}

 }
 @media only screen and (max-width: 575px) {
 .menuanimation-fr {
  display: flex;
  flex-direction: column;
}

.explorecardmasonry {
    padding: 0px;
  }
}
