.pointBox {
  width: 202px;
  margin: 20px auto 30px;
  text-align: center;
  text-transform: uppercase;
}
.pointpara {
  text-align: center;
   color: #292D36;
  font-size: 14px;
  font-weight: bold;
  margin: 12px 0 25px;
}
.pointBox img {
  width: 55px;
}

.pointBox p {
  font-size: 14px;
  margin: 15px 0;
}

.pointBox .pointCount {
  background: #FAFBFC;
  border-radius: 6px;
  padding: 13px;
  text-align: center;
  font-size: 17px;
  color: #E5AB4E;
  font-weight: 600;
}

.pointTable strong {
  font-size: 16px;
}

.pointTable strong.yellow {
  color: #E5AB4E;
}

.modalPrimaryBtn {
  background: linear-gradient(to right, #3B291D, #553A28);
  border: none;
  /* width: 125px; */
  height: 50px;
  border-radius: 6px;
  margin-top: 20px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.modalSecondaryBtn {
  background: transparent;
  border: none;
  margin-top: 16px;
  color: #939393;
  font-size: 20px;
  font-weight: 600;
}