.signout-whiteBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    background-color: #fff;
    border-radius: 6px;
    padding: 15px;
    box-shadow: 0px 0px 20px #0000000D;
    font-size: 14px;
}
@media screen and (max-width: 768px) {
    .signout-whiteBox {
        margin-left: 10px;
    }
}

@media screen and (max-width: 411px) {
    .signout-whiteBox {
        margin-left: 10px;
    }
}

.signoutBtn {
  background: linear-gradient(to right, #3B291D, #553A28);
    border: none;
    width: 140px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}