
.ordermodeContainer{
  display: grid;
   grid-template-columns: 350px 350px; 
}

.ordermodebox {
  height: 95px;
  background: rgb(85 58 40 / 70%) 0% 0% no-repeat padding-box;
backdrop-filter: blur(3px) !important;
border-radius: 4px;
opacity: 1;
backdrop-filter: blur(3px);
-webkit-backdrop-filter: blur(3px);
margin: 10px;
padding: 10px;
/* margin-right: 30px  !important; */
}




.refer-friends-header {
letter-spacing: 2px !important;
font: normal normal bold 20px/24px Lato !important;
color: #553A28;
text-transform: uppercase;
opacity: 1;
/* padding-top: 43px !important; */
}

.refer-friends-para  {
    text-align: left !important;
letter-spacing: 0px !important;
color: #2C2C2C !important;
opacity: 1;
font-size: 16px !important;
    font-weight: normal;
    font-family: 'Lato';
    height: 70px;
    /* width: 372px; */

}
.newcountries {
    display: flex;
    flex-direction: column;
}
.newcountriespara {
    color: #553A28 !important;
    font-size: 18px;
}
.refer-section-fr-icons {
    background: #FAFBFC 0% 0% no-repeat padding-box !important;
border-radius: 8px !important;
opacity: 1;
display: flex;
flex-direction: row;
justify-content: space-evenly;
padding-top:19px;
padding-bottom: 19px;
max-width: 458px;
min-width:300px;
margin-bottom: 10px;
}

@media only screen and (max-width: 411px) {
    /* .refer-section-fr-icons {
    background: #FAFBFC 0% 0% no-repeat padding-box !important;
border-radius: 8px !important;
opacity: 1;
display: flex;
flex-direction: row;
justify-content: space-evenly;
padding-top:19px;
padding-bottom: 19px;
width: 300px !important;
margin-bottom: 20px !important;
} */
.homepagefeaturestitle {
    font-size: 16px !important;
}
}

@media only screen and (max-width: 768px) {
  
  .ordermodeContainer{
    display: flex;
    flex-direction: column;
     /* grid-template-columns: 350px 350px;  */
  }
  
    .about-services-main h2 {
        font-size: 14px !important;
    }
    .about-service-column {
        padding-bottom: 51px !important;
    }
    .about-service-column-fr {
        padding-bottom: 31px !important;
    }
    .refer-section-fr-icons {
        /* margin-bottom: 20px !important; */
    }
    .newcountriespara {
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 650px) {
    .refer-section-fr-icons {
        /* width: 370px !important; */
    }
}
@media only screen and (max-width: 600px) {
    .refer-section-fr-icons {
        /* width: 370px !important; */
    }
    .newcountriespara {
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 375px) {
    .refer-section-fr-icons {
    /* background: #FAFBFC 0% 0% no-repeat padding-box !important;
border-radius: 8px !important;
opacity: 1;
display: flex;
flex-direction: row;
justify-content: space-evenly;
padding-top:19px;
padding-bottom: 19px;
width: 300px  !important; */
/* margin:34px; */
}
.newcountriespara {
    font-size: 12px !important;
}
}

.refer-section-fr-icontext {
    text-align: left;
   width: 84px !important;
     font: normal normal 600 16px/19px Lato;
    font-weight: 600 !important;
    font-size: 13px !important;
    font-family: Lato !important;
    letter-spacing: 0px !important;
    color: #4D3A21 !important;
    opacity: 1;
    
}
.featuremain {
    padding: 25px !important;
}
.refer-section-fr-icontext-two {
    width: 114px !important;
     font: normal normal 600 16px/19px Lato;
    font-weight: 600 !important;
    font-size: 13px !important;
    font-family: Lato !important;
    letter-spacing: 0px !important;
    color: #4D3A21 !important;
    opacity: 1;
    
}

.refer-section-fr-button {
       min-width: 270px;
    max-width: 292px;
        height: 50px;
    background-color: #E5AB4E !important;
    color: #2C2C2C !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    padding-left: 13px !important;
     border-radius: 6px !important;
border: none !important;
font: normal normal bold 20px/24px Lato;
font-size: 20px !important;
font-weight: bold !important;
line-height: 24px !important;
letter-spacing: 0px;
color: #2C2C2C !important;
text-transform: none !important;
opacity: 1;
text-align: start;
 }