.yr-root{
	display: flex;
	flex-direction: column;
	height: 28vh;
	width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width:none;
	scroll-behavior: smooth;
}

.yr-root::-webkit-scrollbar{
display: none;
}

.yr-card{
  display: flex;
	flex-direction: row;
	align-items: center;
	margin: 5px;
	width: 100%;
	cursor: pointer;
	height: fit-content;
 	border-bottom: 1px solid #F7F7F7;
	padding: 5px;
	/* margin-top: 15px; */
}

.yourlocation {
	text-align: start !important;
}
.yourlocation-fr {
	text-align: start !important;
}
 