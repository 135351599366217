@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
body {
  font-family: 'Lato', sans-serif;
}
.notfound {
  margin-top: 203px !important;
  margin-bottom: 203px !important;
}
.notfound-text {
  text-align: center !important;
  color:grey;
  font-family: 24px !important;

}
.notfound-text ::after {
  content: "|";
  font-size: 24px;
  color: #2C2C2C;
  opacity: 0.2;
}
.notfound-tag {
  text-align: center !important;
  color:rgb(37, 36, 36) !important;
  padding-top: 20px !important;
  letter-spacing: 1.2px !important;
}
.newname {
  font-size: 12px !important;
  text-align: start;
  margin-bottom: 0px !important;
  color: #A77D61 !important;
  font: normal normal 600 16px/19px Lato !important;

}
.locationtext-fr-new {
  text-align: start !important;
  /* width: 125px !important; */
  width: 139px !important;
  margin-bottom: 0px !important;
  color: #A77D61 !important;
  font-size: 11px ;
  font: normal normal 600 16px/19px Lato !important;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.locationtext-fr-new::after {
  content: "|";
  font-size: 11px;
  padding: 6px 151px;
  position: absolute;
  color: #2C2C2C;
  opacity: 0.2;
  display: none;
}
.locationtext-fr {
  text-align: start !important;
  width: 125px !important;
  margin-bottom: 0px !important;
  color: #A77D61 !important;
  font-size: 12px !important;
}
.themed-navbar {
  top: 0px;
  left: 0px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  /* box-shadow: 0px 5px 7px #00000021; */
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1) ;
  height: 77px !important;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.themed-navbar-hidden{
   transform: translateY(-110%);
}

.headernav {
  height: -77px !important;
}


/* .accountuser-fr {
  margin-top: -23px !important;
  padding-right: 43px !important;
} */
.accountuser-fr::after {
  content: "|";
  font-size: 11px;
  padding: 6px 151px;
  position: absolute;
  color: #2C2C2C;
  opacity: 0.2;
  display: none;
}

.myaccountname {
  font-size: 13px  !important;
  padding: 0px !important ;
  cursor: pointer;
}
.nav-a::after {
    content: "|";
    font-size: 11px;
    padding: 6px 3px;
    position: absolute;
    color: #2C2C2C;
    opacity: 0.2;
}
.nav-b::after {
  content: "|";
    font-size: 11px;
    padding: 6px 3px;
    position: absolute;
    color: #2C2C2C;
    opacity: 0.2;
}
.orderitbtn {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
    text-align: center;
font: normal normal 600 13px/17px Lato !important;
letter-spacing: 2.1px ;
color: #FFFFFF !important;
text-transform: uppercase !important;
opacity: 1;
margin-top: 0px !important;
/* font-weight: lighter !important; */
font-weight: 500 !important;
}
.orderitbtn-fr {
  letter-spacing: 1px !important;
}
.navbar-brand {
  padding-top:0px !important;
  padding-bottom: 0px !important;
}
.navbar {
  height: 77px !important;
  padding-top:0px !important;
  padding-bottom: 0px !important;
}
.navbar-expand-lg .navbar-nav .nav-link  {
  padding-right: 12px !important;
  padding-left: 12px !important;
}
.d-flex {
  display: flex;
}
.navbar-nav {
  padding-left: 5px;
  padding-right: 5px;
  width: max-content;
  /* margin-right: 145px !important; */
}
.align-items-center {
  align-items: center;
}
.container-fluid {
  padding:0x !important;
}
.themed-nav-right li a i {
  margin-top:auto !important;
  margin-bottom:auto !important;
min-width: 25px;
height: 25px;
font-size:25px;
color: #553A28 !important;
text-align: center !important;
}
.nav-link:active {
  color:#553A28;
  font-weight:bold;
}
.themed-nav-right span {
  top: 20px;
left: 876px;
height: 19px;
text-align: left;
font: normal normal bold 16px/19px Lato;
letter-spacing: 0px;
color: #553A28;
opacity: 1;
}
.themed-nav-right p {
font: normal normal normal 12px/17px Lato !important;
letter-spacing: 0px !important;
color: #A77D61 !important;
opacity: 0.9;
margin-bottom: 0px !important;
}
.locationicon {
  padding: 0px !important;
}

p{
text-align: left;
font: normal normal normal 14px/21px Lato;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
}
.themed-nav-right 
#navbarSupportedContent {
  margin:0px !important;
  padding:0px !important;
}
.header {
  padding: 10px 5px;
  background: #fff;
  box-shadow: 0px 3px 0px 4px #ECEEF4C4;
  margin-bottom: 25px;
}
.logo img {
  height: 50px;
}
.headerMenu {
  margin-left: 15px;
}

.headerMenu a {
  color: #2C2C2C;
  opacity: 0.5;
  padding: 6px 11px;
  font-weight: 600;
  text-decoration: none;
  text-align: left;
  font: normal normal 600 18px/22px Lato;
  letter-spacing: 0px;
}
.headerMenu a:active {
  color: #553A28 !important;
  opacity: 1 !important;
}
.homenew {
  color:#553A28 !important;
  opacity: 1 !important;
  font: normal normal bold 16px/19px Lato !important;
  font-weight: bold !important;
  font-family: 'Lato' !important;

  letter-spacing: 0px;
}
.nav-a {
  color:#553A28 !important;
  text-align: center !important;
  padding-top: 22px !important;
    padding-bottom: 21px !important;
}
/* .nav-a::after {
  display: none !important;
} */
.vertline {
  padding-left:12px !important;
  padding-right: 12px !important;
  height: 7px !important;
  width: 0px !important; 
opacity: 0.1 !important;
}
.profileimg {
  height: 34px !important;
  width: 34px !important;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: baseline !important;
  cursor: pointer;
}
.navbar-nav.active {
  color:#553A28 !important;
  opacity: 1 !important;
  text-decoration: none;
}
.nav-link {
  font: normal normal 600 16px/19px Lato !important;
letter-spacing: 0px !important;
color: #2C2C2C;
opacity: 0.5 ;
}
.nav-link:active {
  text-decoration: none;
  color: #553A28 !important;
  font: normal normal 600 18px/22px Lato;
  letter-spacing: 0px;
  font-family: 'Lato';
  opacity: 1 !important;
}
.nav-item  i{
  color:#553A28  !important;
}
.nav-link-fr {
  color: #2C2C2C !important;
}
.navbar-nav a {
  color:#553A28;
  /* opacity: 0.5; */
  opacity: 0.8;
  padding: 6px 11px;
  font-weight: 600;
  text-decoration: none;
  text-align: left;
  font: normal normal 600 18px/22px Lato;
  letter-spacing: 0px;
}
.nav-item:active {
  color: #553A28 !important;
}
.rightMenu {
  margin-top:0px !important;
  height: 34px !important;
}
.rightMenu a {
  color:  #553A28 !important;
opacity: 1;
  border-left-style: groove #2C2C2C;
}

.rightMenus a img {
  height: 25px;
}

.headerRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.location {
  margin-right: 25px;

}
.location strong {
  color: #553A28;
  font-size: 16px;
}
.location p {
  color: #A77D61;
  font-size: 14px;
  margin-bottom: 0;
}
.navbar-toggler {
  border: none !important;
}
.basketimg {
  height:35px;
  width:35px;
  padding-left:0px !important;
}

@media screen and (max-width: 500px) {
  .themed-nav-right  {
    display: flex !important;
    flex-direction: column !important;
  }
  .navbar-brand img {
    align-items: center;

  }
}
@media screen and (max-width: 1126px) {
  .navbar-nav {
    width:100%;
  }
.nav-a::after {
    content: "|";
    font-size: 11px;
    padding: 20px 3px;
    color: #2C2C2C;
    opacity: 0.2;
    position: absolute;
    top: 10px !important;
}
.nav-b::after {
  content: "|";
    font-size: 11px;
    padding: 0px 3px !important;
    color: #2C2C2C;
    opacity: 0.2;
    position: absolute;
    top: 8px !important;
}
.rightMenu {
  margin-right: 10px !important;
}
}
@media screen and (max-width: 1280px) {
  .nav-a::after {
    content: "|";
    font-size: 11px;
    padding: 20px 3px;
    color: #2C2C2C;
    opacity: 0.2;
    position: absolute;
    top: 10px !important;
}
.nav-b::after {
  content: "|";
    font-size: 11px;
    padding: 0px 3px !important;
    color: #2C2C2C;
    opacity: 0.2;
    position: absolute;
    top: 8px !important;
}
.rightMenu {
  margin-right: 10px !important;
}
}
@media screen and (max-width: 991px) {
  .navbar-collapse {
    background: #fff;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 19px;
    padding-left: 20px;
  }
  .nav-a::after {
    color: #2C2C2C;
    opacity: 0.2;
    position: unset;
}
.nav-b::after {
 
    color: #2C2C2C;
    opacity: 0.2;
}
/* .quanty {
  left: 30px !important;
  margin-top:1px;
} */


}
@media screen and (max-width: 600px) {
  .navbar  {
    position: relative;
  }
  .navbar-toggler {
    position: absolute;
    border: none !important;
    float: left !important;
    top: 20px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .logoheader {
    margin-left: 31px !important;
    
  }
  .navbar-brand {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .orderitbtn {
    position: absolute;
    top: 20px !important;
    right: 10px !important;
    margin-top:0px !important;
  }
  .rightMenu a {
    padding-left: 0px !important;
      font: normal normal 600 16px/19px Lato !important;
      letter-spacing: 0px !important;
      color:rgba(0,0,0,0.5) !important;
      opacity: 0.5 !important;
  }
  .cart-quantity {
    font-size: 17px;
    left: 41px !important;
    top: 1px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    width: 20px;
    height: 20px; 
    position: absolute;
  }
  .cart-quantity-two {
    font-size: 17px !important;
    width: 20px;
    height: 20px; 
    color: #CE4632 !important;
  }
  .navbar-collapse {
    background-color: #FFFFFF !important;
    margin-top: -10px !important;
  }
  .nav-b::after {
    color: #fff !important;
  }
  .nav-a::after {
    color: #fff !important;
  }
  .accountuser-fr {
    padding-right: 0px !important;
    padding-left: 10px !important;
    margin-top: 10px !important;
    margin-left: -15px !important;
  }
  .accountuser-fr::after {
    display: none !important;
  }
  
  .location-mobile {
    position: absolute;
    top: 20px !important;
    left: 113px;
    display: flex;
  }
  .ordernow-mobile {
    position: absolute;
    top: 0px !important;
    right: 10px;
    display: flex;
  }
  .nav-link-fr {
    margin-top: 10px !important;
    opacity: 1 !important;
  }
  .right-fr-cart {
    margin-top: 20px !important;
    padding-left: 10px !important;
    position: unset !important;
    list-style-type: none;
  }
  .accountuser-fr ::after {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .navbar  {
    position: relative;
  }
  .navbar-toggler {
    position: absolute;
    border: none !important;
    float: left !important;
    top: 20px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .logoheader {
    margin-left: 31px !important;
    
  }
  .accountuser-fr {
    margin-top: 4px !important;
    padding-left: 10px !important;
    margin-left: -15px !important;
  }
  .navbar-brand {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .orderitbtn {
    position: absolute;
    top: 20px !important;
    right: 10px !important;
    margin-top:0px !important;
  }
  .rightMenu a {
    padding-left: 0px !important;
      font: normal normal 600 16px/19px Lato !important;
      letter-spacing: 0px !important;
      color:rgba(0,0,0,0.5) !important;
      opacity: 0.5 !important;
  }
  .cart-quantity {
    font-size: 17px;
    left: 41px !important;
    top: 1px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    width: 20px;
    height: 20px; 
    position: absolute;
  }
  .newname {
    padding-left: 20px !important;
    padding-bottom: 20px !important;
  }
  .cart-quantity-two {
    font-size: 17px !important;
    width: 20px;
    height: 20px; 
    color: #CE4632 !important;
  }
  .navbar-collapse {
    background-color: #FFFFFF !important;
    margin-top: -2px !important;
  }
  .nav-b::after {
    color: #fff !important;
  }
  .nav-a::after {
    color: #fff !important;
  }

}
@media screen and (max-width: 768px) {
    .location-mobile {
      display: flex;
      align-items: center;
      position:absolute;
      right: 190px !important;
    }
    .locationtext-fr-new {
      width: 173px !important;
      text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    }
}
@media screen and (max-width: 450px) {
  .logoheader {
    margin-left: 30px !important;
  }
  .nav-b::after {
    color: #fff !important;
  }
  .nav-a::after {
    color: #fff !important;
  }
  .loginasbtn-fr {
    height: 37px !important;
    width: 146px !important;
    opacity: 1 !important;
    color: #fff !important;
    background: #553a20 !important;
    opacity: 1 !important;
    color: #fff !important;
    text-align: center;
    padding: 10px !important;
    margin-top: 10px !important;
    border-radius: 6px !important;
    margin-top: -10px !important;
    font: normal normal 600 13px/17px Lato !important;
    letter-spacing: 2.1px !important;
  }
  .new-fr-login {
    margin-top: 20px !important;
    margin-left: 10px !important;
  }
  .new-fr-bottom-login {
    margin-bottom: 20px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .orderitbtn {
    font: normal normal 600 10px Lato !important;
    letter-spacing: 1px !important;
    width: 100px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .profileimg-mobile-fr {
    position: absolute;
    top: 23px !important;
    right: 20px !important;
  }
  .quanty {
    right: 13px !important;
    margin-top: 22px;
}
.address-select-wrap {
  padding-left: 10px !important;
}
  .navbar-collapse {
    margin-top: -13px !important;
    margin-right: -30px !important;
  }
  .account-fr-mobile {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .locationtext-mobile {
    font: normal normal 600 16px/19px Lato !important;
    letter-spacing: 0px !important;
    text-align: start !important;
    color: #2C2C2C !important;
    opacity: 0.5 !important;
    padding-left: 20px;
  }
  .location-mobile {
    width: 169px !important;
  }
  .locationtext-fr-new {
    font-size: 12px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
@media screen and (max-width: 375px) {
  .logoheader {
    margin-left: 30px !important;
  }
  .nav-b::after {
    color: #fff !important;
  }
  .nav-a::after {
    color: #fff !important;
  }
  .loginasbtn-fr {
    height: 37px !important;
    width: 146px !important;
    opacity: 1 !important;
    color: #fff !important;
    background: #553a20 !important;
    opacity: 1 !important;
    color: #fff !important;
    text-align: center;
    padding: 10px !important;
    margin-top: 10px !important;
    border-radius: 6px !important;
    margin-top: -10px !important;
    font: normal normal 600 13px/17px Lato !important;
    letter-spacing: 2.1px !important;
  }
  .rightmenu-fr {
    margin-top: -10px !important;
    margin-left: 10px !important;
  }
  .rightmenu-fr {
    opacity: 1 !important;
  }
  .new-fr-login {
    margin-top: 20px !important;
    margin-left: 10px !important;
    margin-bottom: 20px !important;
  }
  .new-fr-bottom-login {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    margin-left: -10px !important;
  }
  .navbar-collapse {
    margin-top: -13px !important;
    margin-right: -30px !important;
  }
  .orderitbtn {
    font: normal normal 600 10px Lato !important;
    letter-spacing: 1px !important;
    width: 100px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .quanty {
    right: 13px !important;
    margin-top: 22px !important ;
}
.address-select-wrap {
  padding-left: 10px !important;
}
.location-mobile {
  width: 139px !important;
}
}






