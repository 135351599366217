.modal-dialog {
 display: flex;
    align-self: center;
    max-width: 500px;
    margin: 80px auto;
}
.addr-form-control{
      width: 100% !important;

}

.modal-body .form-control {
  width: 100%;
  background: #F0F1F2;
  border: none;
  height: 40px;
  border-radius: 6px;
  /* padding: 0px !important; */
  margin-bottom: -5px !important;
  font-size: 15px;
  color: #2C2C2C;
}
.newaddressmodal {
  margin-top: 1px !important;
}
.address-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #553A28;
  font-size: 23px;
  font-weight: bold;
  margin-top: -10px;
}
.labellandmark{
  float: left !important;
}
.modelForm {
  	display: flex;
	flex-direction: column;
	max-height: 265px;
	width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: scroll;
    overflow: auto;
	scrollbar-width:thin;
    /* scroll-behavior: auto; */
	scroll-behavior: smooth;
      /* margin: 0 20px 0px 20px; */
  /* z-index: 200; */
}

/* .modelForm::-webkit-scrollbar{
display: none;
} */

/* .modelForm input {
  margin-top: 20px;
    z-index: 200;
} */
.addr-main-modal {
     max-width: 500px !important;

      height: -webkit-fill-available;

    /* top:5%;
    margin-top: 0px !important; */
}

.modelFooter .modalPrimaryBtn {
  margin-top: 0;
}

.addr-modelFooter {
  box-shadow: 0px -3px 6px #656A7971;
  margin: 0px -26px -26px;
  padding: 12px;
}

.modelMap {
  margin: 0 -26px;
 }

 .modalAddressBtn {
    background: linear-gradient(to right, #3B291D, #553A28);
    border: none;
    width: 100%;
    height: 43px;
    border-radius: 6px;
    margin-top: 5px;
    /* margin: 5px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

 .address-root{
	display: flex;
	flex-direction: column;
	height: 28vh;
	width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width:none;
	scroll-behavior: smooth;
}

.radio-compatible{
    -webkit-appearance: radio;
}

.address-alert-anim{
	color: #FAFBFC;
	background-color: #CE4632;
	position: absolute;
	bottom: 68px;
	padding: 6px;
	font-size: 13px;
	width: 90% ;
	border-width: 0px;
	animation: addressalertmove 0.4s ;
}
@keyframes addressalertmove {
  from {bottom: 48px;}
  to {bottom: 68px;}
}
