.slides {
  position: relative;
}
.slides img {
  /* height: 329px; */
  max-height: 400px !important;
  width: calc(100% - 20px);
  background: transparent linear-gradient(179deg, #00000000 0%, #000000 100%);

}
.slideContent {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  /* background: rgba(196, 112, 62, 0.5) */
  /* background: linear-gradient(to top right, rgb(85 58 40), transparent); */
  /* background: linear-gradient(to bottom left, transparent,rgb(85 58 56)); */
  /* background: linear-gradient(to left, transparent,rgb(14 9 9)); */
 background: linear-gradient(to left bottom, transparent 45%,rgba(30 22 22) 87%);
  /* background: linear-gradient(to left bottom, transparent 61%,rgba(30 22 22) 80%); */
  width: 75vw;
  height: 30vw;
  border-radius: 6px;
}
.styban {
  height:200px !important;
}

.imgpad1 {
  opacity: 0.6;
}
.stytext {
  background: #C4703E;
  padding: 10px 20px !important;
  border-radius: 6px;
  font-size: 14px !important;
  display: inline-block;
  text-transform: uppercase !important;
  font-weight: 600;
  position: absolute;
   /* top: 278px; */
   bottom: 79px;
    left: 25px;
}
.slideContent .slideTag {
  background: #C4703E;
  padding: 10px 20px !important;
  border-radius: 6px;
  font-size: 14px;
  display: inline-block;
}
.slideContent .slideHeading {
  font-size: 24px;
  font-weight: 600;
  position: absolute; 
  top: 318px;
   left: 25px;
}
.slideContent p {
  text-transform: none;
  font-weight: 500;
  margin-bottom: 0;
}
.stytext {
  text-align: center;
  font: 14px Lato;
  letter-spacing: 0px;
  color: #FFFFFF !important;
  opacity: 1;
}
.bigsavingstext {
  color:#FFFFFF !important;
}
@media screen and (max-width: 768px) {
  .bigsavingstext-fr {
    text-align: left;
    font: normal normal normal 12px Lato;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    opacity: 1;
    position: absolute; 
    /* top: 185px !important; */
    /* padding-top: 10px; */
     left: 25px;
     bottom: 20px !important;
     padding-right: 10px !important;
  }
  .styHeading-fr {
    text-align: left;
    font: normal normal bold 16px/36px Lato !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
    left: 25px;
    /* top: 161px !important; */
    bottom: 41px !important;
  }
  .stytext {
    top:auto !important;
    background: #C4703E;
    padding: 10px 20px !important;
    border-radius: 6px;
    font-size: 14px !important;
    display: inline-block;
    text-transform: uppercase !important;
    font-weight: 600;
    position: absolute;
      left: 25px;
      bottom: 81px !important;
  }
}
@media screen and (max-width: 767px) {
  .slides img {
    width: 100%;
  }
  .stytext {
    background: #C4703E;
    padding: 10px 20px !important;
    border-radius: 6px;
    font-size: 14px !important;
    display: inline-block;
    text-transform: uppercase !important;
    font-weight: 600;
    position: absolute;
     /* top: 490px; */
      left: 25px;
      bottom: 81px !important;
  }
  .styHeading-fr {
    text-align: left;
font: normal normal bold 24px/36px Lato;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
position: absolute; 
/* top: 539px;  */
left: 25px;
bottom: 41px;
}
.bigsavingstext-fr{
    text-align: left;
font: normal normal normal 15px Lato;
letter-spacing: 0px;
color: #FFFFFF !important;
opacity: 1;
position: absolute; 
/* top: 572px; */
/* padding-top: 10px; */
 left: 25px;
 bottom: 20px;
}

}
@media screen and (max-width: 545px) {
  .slideContent {
    background: linear-gradient(to bottom left, transparent,66%,rgb(14 9 9)) !important;
  }
  .stytext {
    background: #C4703E;
    padding: 5px 10px !important;
    border-radius: 6px;
    font-size: 12px !important;
    display: inline-block;
    text-transform: uppercase !important;
    font-weight: 600;
    position: absolute;
     /* top: 466px; */
      left: 25px;
      bottom: 81px !important;
  }
  .styHeading-fr {
    text-align: left;
font: normal normal bold 13px/36px Lato !important;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
position: absolute; 
/* top: 499px !important;  */
left: 25px;
bottom: 41px;
}
.bigsavingstext-fr{
    text-align: left;
font: normal normal normal 12px Lato;
letter-spacing: 0px;
color: #FFFFFF !important;
opacity: 1;
position: absolute; 
/* top: 524px !important; */
/* padding-top: 10px; */
 left: 25px;
 bottom: 20px;
 padding-right: 10px !important;
}


}

@media screen and (max-width: 532px) {  
  .slideContent {
    background: linear-gradient(to bottom left, transparent,66%,rgb(14 9 9)) !important;
  }
  .stytext {
    background: #C4703E;
    padding: 5px 10px !important;
    border-radius: 6px;
    font-size: 12px !important;
    display: inline-block;
    text-transform: uppercase !important;
    font-weight: 600;
    position: absolute;
     /* top: 451px; */
      left: 25px;
      bottom: 81px !important;
  }
  .styHeading-fr {
    text-align: left;
font: normal normal bold 13px/36px Lato !important;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
position: absolute; 
/* top: 482px !important;  */
left: 25px;
bottom: 41px;
}
.bigsavingstext-fr{
    text-align: left;
font: normal normal normal 12px Lato !important; 
letter-spacing: 0px;
color: #FFFFFF !important;
opacity: 1;
position: absolute; 
/* top: 508px !important; */
/* padding-top: 10px; */
 left: 25px;
 bottom: 20px;
 padding-right: 10px !important;
}


}

@media screen and (max-width: 500px) {
  .slideContent {
    background: linear-gradient(to bottom left, transparent,66%,rgb(14 9 9)) !important;
  }
  .stytext {
    background: #C4703E;
    padding: 5px 10px !important;
    border-radius: 6px;
    font-size: 12px !important;
    display: inline-block;
    text-transform: uppercase !important;
    font-weight: 600;
    position: absolute;
     /* top: 422px; */
      left: 25px;
      bottom: 81px !important;
  }
  .styHeading-fr {
    text-align: left;
font: normal normal bold 13px/36px Lato !important;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
position: absolute; 
/* top: 454px !important;  */
left: 25px;
bottom: 41px;
}
.bigsavingstext-fr{
    text-align: left;
font: normal normal normal 12px Lato !important;
letter-spacing: 0px;
color: #FFFFFF !important;
opacity: 1;
position: absolute; 
/* top: 477px !important; */
/* padding-top: 10px; */
 left: 25px;
 bottom: 20px;
 padding-right: 10px !important;
}


}



@media screen and (max-width: 475px) {
  .slideContent {
    /* background: linear-gradient(to bottom left, transparent,66%,rgb(14 9 9)) !important; */
    background: linear-gradient(to left bottom, transparent 60%,rgba(30 22 22) 79%) !important;

  }
  .stytext {
    background: #C4703E;
    padding: 5px 10px !important;
    border-radius: 6px;
    font-size: 12px !important;
    display: inline-block;
    text-transform: uppercase !important;
    font-weight: 600;
    position: absolute;
     /* top: 329px !important; */
      left: 25px;
      bottom: 81px !important;
  }
  .styHeading-fr {
    text-align: left;
font: normal normal bold 13px/36px Lato !important;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
position: absolute; 
/* top: 354px !important;  */
left: 25px;
bottom: 41px;
}
.bigsavingstext-fr{
    text-align: left;
font: normal normal normal 12px Lato !important;
letter-spacing: 0px;
color: #FFFFFF !important;
opacity: 1;
position: absolute; 
/* top: 379px !important; */
/* padding-top: 10px; */
 left: 25px;
 bottom: 20px;
 padding-right: 10px !important;
}


}

