.str-root{
	display: flex;
	flex-direction: column;
	height: 60vh;
	width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width:none;
	scroll-behavior: smooth;
}

.str-root::-webkit-scrollbar{
display: none;
}

.str-card{
	display: flex;
	flex-direction: row;
	align-items: center;
 	width: 100%;
     position: relative;
	height: fit-content;
	justify-content: space-between;
 	padding: 5px;
	margin-top: 15px;
}

 .text-color{
	 text-align: left;
	color: #553A28 !important;
	font: normal normal 800 16px Lato;
	margin-bottom: 3px;
 }

 
 .text-color-faint{
	 text-align: left;
	color: #727272 !important;
	font: normal normal  14px Lato;
	margin-bottom: 3px;
 }

 .serv-img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.mode-chip{
  display: flex; 
  align-items: center; 
  justify-content: center;
  width: max-content;
  padding-inline: 15px;
  padding-left: 15px;
  /* padding-right: 5px; */
  padding-top: 3px;
  padding-bottom: 3px;
  border-width: 1px;
  border-radius: 15px;
  margin-right: 10px;
  margin-top: 10px; 
  cursor: pointer;
  box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.5);
  font-size: 12px;
  background-color: #A77D61;
  color: #fff;
 }

.mode-chip-disabled{
  display: flex; 
  align-items: center; 
  justify-content: center;
  width: max-content;
  padding-inline: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-width: 1px;
  border-radius: 15px;
  margin-right: 10px;
  margin-top: 10px; 
   box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.5);
  font-size: 12px;
  background-color: #A77D61;
  color: #fff;
   -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

