.modal-body .emailInput {
  width: 100%;
  background: #F0F1F2;
  border: none;
  height: 45px;
  border-radius: 6px;
  padding: 15px;
}

.modalPrimaryBtn {
  background: linear-gradient(to right, #3B291D, #553A28);
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.modalSecondaryBtn {
  background: transparent;
  border: none;
  margin-top: 16px;
  color: #939393;
  font-size: 20px;
  font-weight: 600;
}

.haveAcc {
  margin-top: 25px;
  color: #748090;
  font-size: 14px;
}

.haveAcc a {
  color: #553A28;
  font-weight: 600;
}

.divider {
  display: flex;
  align-items: center;
  margin: 25px 0 15px;
}

.dividerBorder {
  width: 100%;
  height: 1px;
  background: #F4F4F4;
}

.dividerText {
  color: #748090;
  font-size: 12px;
  margin: 0 15px;
}

.modalSocial img {
  margin: 0 10px;
}

.contactNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactNumber p {
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px;
  color: #A77D61;
}

.contactNumber button {
  background: #A77D61;
  color: #fff;
  border: solid 1px #9B7257;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px 9px;
}

.otp {
  margin-top: 65px;
}

.otp input {
  width: 50px;
  height: 50px;
  background: #F0F1F2;
  border-radius: 6px;
  font-size: 28px;
  text-align: center;
  border: none;
  margin: 0 13px;
}

.otp input:focus {
  background: #fff;
  border: solid 2px #F0F1F2;
  outline: none;
}

.otp input::-webkit-outer-spin-button,
.otp input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
}

.resend {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #49CE8B;
  font-size: 16px;
  font-weight: 600;
  margin: 25px 0 0px;
}

.resend img {
  margin-left: 7px;
}


/* Gift Card */
.ModalTabs {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 7px 4px #ECEEF4C4;
}

.ModalTabs button {
  background: transparent;
  font-size: 14px;
  text-transform: uppercase;
  width: 50%;
  border: none;
  border-radius: 4px;
  height: 35px;
  letter-spacing: 2px;
  white-space: nowrap;
}

.ModalTabs button.active {
  background: #4D3A21;
  color: #fff;
}

.giftCardBox {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #DDB360, #E4AB4F);
  padding: 12px;
  border-radius: 8px;
  margin-top: 17px;
}

.giftCardBalance {
  padding: 10px 20px;
  background: #f7bf64;
  border-radius: 6px;
  margin-left: auto;
  color: #553A28;
  font-size: 12px;
  text-transform: uppercase;
}

.giftCardDetails {
  margin-left: 10px;
  text-align: left;
  font-size: 12px;
}

.giftPrice {
  font-size: 16px;
  font-weight: 500;
}

.balancePrice {
  font-size: 18px;
  margin-top: 3px;
}

.giftId {
  text-transform: uppercase;
}