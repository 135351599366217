.footer {
    background-color: #fff;
    padding: 25px 30px 10px;
}

.footer h3 {
text-align: left;
font: normal normal bold 14px/17px Lato;
letter-spacing: 0px;
color: #553A28;
text-transform: capitalize;
opacity: 1;
}

.MuiPaper-root  {
    width: 327px !important;
}
.footer ul {
    list-style: none;
    padding-left: 0;
}

.footer ul li {
    line-height: 30px;
}
.footer ul li a{
    color: #3B444D;
    font-size: 14px;
}
.copyRight {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}
.copyRight p {
    color: #3B444D;
    font-size: 12px;
    text-align: center !important;
    margin-top: 2rem;
}
.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.footerCol li {
    list-style: none;
     line-height: 30px;
}
.appnewfooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -10px;
}
/* .footer-fr {
    width: 380px;
} */
.appnewfollow {
    margin-bottom: 20px!important;
    margin-top: 5px !important;
}
icons ul {
    display: flex;
}
.d-flex-fr {
    display: flex;
}
.footerCol h5 {
text-align: left;
font: normal normal bold 14px Lato;
letter-spacing: 0px;
color: #553A28;
opacity: 1;
}
@media screen and (max-width:375px) {
.appnewfooter {
    margin-left: 0px !important;
}
.copyRight{
    display: flex;
    flex-direction: column;
    align-items: center !important;
}

}
@media screen and (max-width:420px) {

    .appnewfooter {
        margin-left: -23px !important;
    }
    .copyRight{
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }
    
    
}
@media screen and (max-width:450px) {

    .appnewfooter {
        margin-left: -35px !important;
    }
    .copyRight{
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }
    
    
}
@media screen and (max-width: 767px) {
    .footerContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }    
    .about-welcome-left p {
        justify-content: flex-start;
    }
    .footerCol {
        width: 60px !important;
        margin-left: auto;
        margin-right: auto;
    padding-bottom: 43px;
    }
    .MuiPaper-root  {
        width: 327px !important;
    }
    .customermsg {
        width: 252px !important;
        height: 106px !important;
    }
    .d-flex-fr {
        width: 100px;
        margin-left: 175px !important;
        margin-right: auto;
    }
    .appnewfooter {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 63px ;
    }
    .copyRight{
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }
    
}
@media only screen and (max-width: 600px) {
    .d-flex-fr {
        width: 100px;
        margin-left: 83px !important;
        margin-right: auto;
    }

}