.panel-card {
    box-shadow: 0px 0px 20px #0000000D;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px;
}
.arivaltime {
    font-size: 18px  !important;
    text-transform: none !important;
    letter-spacing: 0px !important;
}
.takeaway-btn {
    background-color: #553A28;
    color: #fff;
    padding: 5px 30px;
    border-radius: 6px;
}
.choose-payment-hide {
    display: none;
}
.choose-order-proceed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.order-address-fr {
    margin-bottom: 20px !important;
}
.choose-delivery {
    margin-bottom: 20px !important;
}
.newnotetext     {
        margin-top: 10px;
    font-weight: 400;
    line-height: 1.5;
    font-size: 15px;
    color: #495057 !important;
    background-color: #fff;
    text-align: justify;
}
@media only screen and (max-width: 768px) {
    .paymenttag {
        display: flex;
        flex-direction: column !important;
        padding-left: 10px;
        padding-bottom: 10px;
    }
    .panel-card-fr {
        padding-left: 0px !important;
    }
    .panel-card {
        padding-bottom: 41px !important;
    }
    .choose-order-proceed {
        display: flex;
        flex-direction: column;
    }
    .order-total{
        padding:10px;
    }
    .your-detail-mobile {
        margin-top: 0px !important;
    }
}


.delivery-scroll{
	display: flex;
	 flex-wrap: wrap;
  min-height: 110px;
  max-height: 350px;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width:none;
	scroll-behavior: smooth;
    margin-top: 15px;
}

/* .delivery-scroll::-webkit-scrollbar{
display: none;
} */
