.main-whiteBox{
       display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    background-color: #fff;
    border-radius: 6px;
    padding: 15px;
     box-shadow: 0px 0px 20px #0000000D;
    font-size: 14px;
}

.mobile-sidebar ul {
    list-style: none;
    width:90vw;
}
.mobile-sidebar ul li {
    padding: 12px;
}
.mobile-sidebar ul li.active {
    background: rgb(85 58 40 / .15);
    border-radius: 4px;
}
.mobile-sidebar ul li.active a {
    color: #553A28;
    font-weight: 600;
}
.mobile-sidebar ul li a {
    color: #748090;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.mobile-sidebar ul li img {
    width: 24px;
    
    
}