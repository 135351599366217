.section-fr {
    margin-top:30px;
    padding-left: 10px !important;
}
.styHeading-fr {
    text-align: left;
font: normal normal bold 20px/36px Lato;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
position: absolute; 
/* top: 318px;  */
top: auto;
bottom: 41px !important;
left: 25px;
}
.bigsavingstext-fr{
    text-align: left;
font: normal normal normal 16px Lato;
letter-spacing: 0px;
color: #FFFFFF !important;
opacity: 1;
position: absolute; 
/* top: 350px; */
top: auto !important;
/* padding-top: 10px; */
bottom: 20px !important;
 left: 25px;
}
.globalimgone {
    width: 75vw !important;
    height: 30vw !important;
}
.slides {
    position: relative;
    padding-right: 15px;
    border-radius: 6px;
}

@media only screen and (max-width: 600px) {
    .globalimgone {
        width: 90vw !important;
        height: 108vw !important;
        border-radius: 6px !important;
    }
    .slideContent {
        background: linear-gradient(to left, transparent,rgb(14 9 9));
        /* background: linear-gradient(to left bottom, transparent 61%,rgba(30 22 22) 80%); */
        width: 90vw !important;
        height: 108vw !important;
        border-radius: 6px;
    }

    .bigsavingstext-fr {
        font-size: 12px !important;
        bottom: 12px !important;
        width: fit-content !important;
    }
    .styHeading-fr {
        font-size: 14px !important;
        /* top: 533px; */
        top: auto !important;
    }
}