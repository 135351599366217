.pointBox {
  width: 202px;
  margin: 20px auto 30px;
  text-align: center;
  text-transform: uppercase;
}
.pointpara {
  text-align: center;
}
.pointBox img {
  width: 55px;
}

.pointBox p {
  font-size: 14px;
  margin: 15px 0;
}
.pointsmodal {
  margin-top: 94px !important;
}

.pointBox .pointCount {
  background: #FAFBFC;
  border-radius: 6px;
  padding: 13px;
  text-align: center;
  font-size: 17px;
  color: #E5AB4E;
  font-weight: 600;
}

.pointTable strong {
  font-size: 16px;
}

.pointTable strong.yellow {
  color: #E5AB4E;
}