.settingmain {
    background-color:#FFFFFF;
    text-align: center;
}
.settinghead {
    color:#553A28;
    font-size:24px;
    line-height:29px;
    text-align:center;
    font-weight: 400;
}
.settingheader {
    padding-top:30px;
    padding-bottom: 80px;
}
.settingheader i {
    color:#4D3A21;
    float: left;
    margin-bottom: 0px !important;
    width:31px;
    height:31px;
    margin-top:0px !important;
}
.editprf {
    float: right !important;
    top:0px;
}
.delivery-text {
    width: 100%;
}
.myaccnt {
    font-size: 16px;
    font-weight: 400;
    color:#2C2C2C;
    height: 19px;
}
.myp {
    color: #2C2C2C;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    opacity: 67%;
}
.editi i {
    float: right !important;
    top:0px;
    color:#2C2C2C !important;
    height:15px;
    width: 19px;
}
.myAccedit {
    color:#2C2C2C;
    font-size: 14px;
    letter-spacing: 2.1px;
    font-weight: bold;
    height: 17px;
}