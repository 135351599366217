#splashspinnertwo {
  display: flex;
}

.divLoadertwo {
  width: 98vw;
  height: 96vh;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* z-index: 9999; */
  /* background-color: #FAFBFC; */
}
.svgLoadertwo {
  height: 80px;
  width:80px;
  animation: spin 1.6s linear infinite;
  margin: auto;
}