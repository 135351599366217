.add-cart-button{
    width: 96px;
    height: 30px;
    margin: 0px;
    font-weight: 600px;
    background-color: #E5AB4E;
    border-color: #E5AB4E;
}

 
.add-cart-button:focus,.add-cart-button:active ,.add-cart-button:hover{
    background-color: #E5AB4E  !important;
    border-color: #E5AB4E !important;
    outline: none !important;
    box-shadow: none !important; 
}

.add-cart-button-group{
  width: 45px;
  height: 30px;
    outline: 0;
    color: #5CB346;
    background-color: #ECEEF4C4;
    font-weight: bold;
}
.add-cart-button-group:focus,
.add-cart-button-group:active ,
.add-cart-button-group:hover{
    color: #5CB346;
    background-color: #ECEEF4C4;
    font-weight: bold;
    outline: none !important;
    box-shadow: none !important; 
}