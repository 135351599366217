@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
/* import FILE_PATH from '/utils/Constatnt/FILE_PATH'; */


body {
  font-family: 'Lato', sans-serif;
  background-color: #FAFBFC;
}

/* My Account */
.myAccount {
  background: #FAFBFC;
  padding: 3rem 1rem;
}
.myAccount-fr {
  margin-top:103px;
}
.accountSidebar ul {
  list-style: none;
}

.accountSidebar ul li {
  padding: 12px;
}

.accountSidebar ul li.active {
  background: rgb(85 58 40 / .15);
  border-radius: 4px;
}

.accountSidebar ul li.active a {
  color: #553A28;
  font-weight: 600;
}

.accountSidebar ul li a {
  color: #748090;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.accountSidebar ul li img {
  width: 24px;
  margin-right: 8px;
}

.whiteBox {
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0px 0px 20px #0000000D;
  font-size: 14px;
}

.myAccHeading {
  color: #E5AB4E;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.myAccSubHeading {
  border-bottom: 1px solid #F4F4F4;
  font-size: 14px;
  color: #553A28;
  text-transform: uppercase;
  margin: 30px 0 20px;
  padding-bottom: 20px;
}

.inputGroup {
  position: relative;
}

.inputGroup input,
.inputGroup select {
  border: 1px solid #E6E6E6;
  background: #FCFCFC;
  width: 100%;
  height: 45px;
  padding: 10px;
  border-radius: 4px;
  font-weight: 600;
}

.inputGroup input::placeholder,
.inputGroup select,
.inputGroup input[type="date"] {
  color: #BCBEC0;
}

.inputGroup button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: no-repeat;
  border: none;
  color: #553A28;
  font-weight: 600;
}

.inputGroup button img {
  width: 20px;
}

/* Order */
.orderBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 15px;
  border: solid 2px #F5F5F5;
  border-radius: 4px;
  margin-top: 25px;
  color: #748090;
}

.orderDetails img {
  width: 15px;
  margin-right: 3px;
}

.orderDetails h6 {
  color: #553A28;
}

.orderDetails .orderQty {
  color: #2c2c2c;
}

.orderDetails .orderItems {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.orderDetails .orderItems span {
  color: #00A56D;
}

.orderDetails .orderPrice {
  margin: 0 10px;
}

.orderBtns button {
  border-radius: 4px;
  text-transform: uppercase;
  border: solid 1px #E5AB4E;
  color: #E5AB4E;
  background: transparent;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 30px;
  width: 175px;
}

.orderBtns button.darkBtn {
  border: solid 1px #553A28;
  color: #553A28;
  margin-right: 30px;
}

/* Address */
.marBot30 {
  margin-bottom: 30px;
}

.btnTrans {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
}

.btnTrans img {
  width: 15px;
  margin-right: 6px;
}
.addtext {
  color:#E5AB4E !important;
}
.add-address,
.address-style {
  min-height: 151px;
}

.addressmain-scroll{
	display: flex;
	 flex-wrap: wrap;
	height: 450px;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width:none;
	scroll-behavior: smooth;
   padding-bottom:50px ;
   padding: 5px;
}

/* .addressmain-scroll::-webkit-scrollbar{
display: none;
} */

/* My Account */
.mydetailsaddress {
  margin-bottom: 20px !important;
}

@media only screen and (max-width: 767px) {
  .mmt {
    margin-top: 1rem;
  }

  .myAccount {
    padding: 0 0 !important;
  }

  .whiteBox {
    padding: 15px !important;
  }

  .orderBox {
    display: block;
  }

  .orderBtns button {
    margin-top: 15px;
  }
}

.address-style {
	border: 2px solid #F5F5F5;
	border-radius: 4px;
	padding: 15px;
	display: flex;
}

.address-style:hover{
    box-shadow: 0 0 7px 4px #eceef4c4;
}
.address-style h4 {
	font-size: 16px;
    line-height: 20px;
    color: #553A28;
    font-weight: bold;
    margin: 0;
}
.address-style p {
	font-size: 14px;
	line-height: 18px;
	color: #2C2C2C;
	opacity: 67%;
	margin: 0;
	padding: 10px 0;
    text-align: start;
}
.address-style h5 {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    color: #2C2C2C;
}