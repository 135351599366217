

.registerheader {
    text-align: center;
    font: normal normal bold 20px/36px Lato;
    letter-spacing: 0px;
    color: #553A28 !important;
    margin-bottom: 32px !important;
    opacity: 1;
}
.modal-dialog {
    margin:100px auto !important;
    max-width:400px;
    
}
.phnnumber {
padding-top:13px;
padding-bottom: 13px;
padding-left: 13px;
text-align: left;
font: normal normal normal 16px/19px Lato;
letter-spacing: 0px;
color: #3B291D;
width:100%;
border: none;
opacity: 1;
background: #F0F1F2 0% 0% no-repeat padding-box;
border-radius: 6px;
opacity: 1;
}
.probtn {
    padding-top:13px;
    padding-bottom: 13px;
    padding-left:10px;
    text-align: left;
    font: normal normal bold 20px/24px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top:23px;
    border: none;
    border-radius: 4px;
    width:100% !important;
    letter-spacing: 1.4px !important;
}
.probtn2 {
    padding-top:13px;
    padding-bottom: 13px;
    padding-left:10px;
    text-align: left;
    font: normal normal bold 20px/24px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top:23px;
    border: none;
    border-radius: 4px;
    width:100% !important;
    background-color: #553A28 !important;
}
.registerarrow {
    float: right;
    padding-left:100px;
}
.loginarrow {
    float: right;
    padding-left:100px;
}
.btn {
    width:100%;
    border-radius: 4px;
    border: none;
}
.secondaryBtn {
    margin-top:23px !important;
    padding-right:8px;
    text-align: right;
    font: normal normal bold 16px/21px Lato;
    letter-spacing: 0px;
    color: #553A20 !important;
    text-transform: lowercase;
    border: none;
    opacity: 1;
    text-align: center;
}
.secondaryBtn-fr {
  padding-top: 10px;
}
.proimg {
    float:right;
}
.loginexist {
    margin-top: 45px;
text-align: center;
font: normal normal bold 14px/26px Lato;
letter-spacing: 0px;
color: #A77D61;
opacity: 1;
}
.loginexist .fa-arrow-circle-right {
    height: 15px;
    color: #A77D61;
    padding-left:8px;
    opacity: 1;
}
.registerSecure {
    background: #FFFFFF !important;
    border-radius: 6px;
    opacity: 1;
}
.emailtxt {
    color:#A77D61 !important;
    
}
.emailtxt i {
    padding-right:10px;
}
.emailbtn {
    background-color: #9B7257;
    color:#FFFFFF;
    padding-left:5px;
    padding-right:5px;
    border:none;
    border-radius:4px;

} 
.nexttxt {
    margin-top:10px;
    text-align: center;
}
.ptext {
    margin-top:10px;
}
.fa-arrow-circle-left {
    float:left;
    color:#9B7257;
}
.countryFlag {
    width: 100%;
}
.loginbtn {
       cursor: pointer;
  text-decoration: none;
  color:#553A28;
}

.modal-body .modalHeading {
  color: #553A28;
  font-size: 30px;
  font-weight: 600;
}
.modal-body .pera {
  color: #292D36;
  font-size: 14px;
  margin: 12px 0 25px;
  text-align: center;
}

.contactNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactNumber p {
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px;
  color: #A77D61;
}
.contactNumber button {
  background: #A77D61;
  color: #fff;
  border: solid 1px #9B7257;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px 9px;
}

.otp input {
  width: 50px;
  height: 50px;
  background: #F0F1F2;
  border-radius: 6px;
  font-size: 28px;
  text-align: center;
  border: none;
  margin: 0 13px;
}
.otp input:focus {
  background: #fff;
  border: solid 2px #F0F1F2;
  outline: none;
}
.otp input::-webkit-outer-spin-button,
.otp input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
}

.resend {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #49CE8B;
  font-size: 16px;
  font-weight: 600;
  margin: 25px 0 0px;
}
.resend img {
  margin-left: 7px;
}

.modalRegBtn {
  background: linear-gradient(to right, #3B291D, #553A28);
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
