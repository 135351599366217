
.feature-cat {
  overflow-x: visible;
  /* margin-bottom: 30px; */
  margin-top: 60px;
  /* background-color: pink; */
}
.imgdetails3 {  
  border: 2px solid #000;
  height: 100px;
  text-align: center;
}

.column {
  display: flex;
  flex-direction: column;
}

button.favaddbutton {
  background: #E5AB4E;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 6px 34px;
  border-radius: 4px;
  float:right;
}
.onlineicon {
  width:12px !important;
  height:12px !important;
  border:6px;
  border: 1px #008000 !important;
}
.oflineicon {
  width:12px !important;
  height:12px !important;
  margin-top:5px;
  margin-right:1px;
  border-radius: 0px !important;
  position: absolute;
  float: right;
  top: 1px !important;
  /* right: 51px !important; */
  right: 21px !important;
}
.feitems {
  display: flex;
}
.ite {
  align-items: flex-start;
}

.featuredName {
  text-align: center;
  justify-content: center;
}
.featuredName h5 {
  padding-top: 20px;
}
 
.featured-carousel-wrap {
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 55px;
   display: flex;
 }

 .heading-fr {
   margin-left:20px;
 }
.featured-card {
  margin: 10px 0;
  /* margin-top: 30px !important; */
  /* margin-left: 80px; */
  background-color: #fff;
  border-radius: 6px;
  padding-left: calc(80px + 15px);
  position: relative;
  /* min-height: 171px; */
  /* max-width: 370px; */
  /* max-width: 453px; */
  /* min-width: 400px; */
  width: 422px;
  height: 130px;
  box-shadow: 0px 0px 20px #0000000D;


}
.featured-card .fc-item-name {
  font-size: 18px;
  margin-bottom: 8px;
  color: #553A28;
}
.featured-card .fc-item-price {
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 18px;
  margin: 0px;
  color:#2C2C2C;
}
.fc-item-price small {
      color: black;
      vertical-align: text-top;
      font-size: 12px;
      letter-spacing: 0px;
}
.featured-card p {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: initial;
}
.featured-card-img {
  height: 161px;
  width: 161px;
  border-radius: 50%;
  position: absolute;
  left: 0px; top: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  overflow: hidden;
  margin-left: 53px !important;
}
.d-new {
  padding: 10px;
  padding-left: 55px !important;
}

.featured-card-img img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
}

.featured-carousel-wrap.owl-carousel .owl-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-30% - 10px));
  left: 0; right: 0;
}
.theme-btn-primary {
  background: #E5AB4E 0% 0% no-repeat padding-box;
border-radius: 4px;
padding-top:5px;
padding-bottom: 5px;
padding-left:30px;
padding-right:30px;
text-align: center;
font: normal normal 600 16px/20px Lato;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
border:none;
}
.theme-btn-primary:focus {
      outline: none;
}
@media screen and (max-width: 450px) {
  .featured-carousel-wrap {
    width: 430px;
    margin-left: 25px;
  }
 
  .d-new {
    padding-left: 35px !important;
  }

  .featured-card-img {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    position: absolute;
    left: 0px; top: 50%;
    transform: translate(-50%, -50%) scale(1.1);
    overflow: hidden;
    margin-left: 53px !important;
  }

  .featured-card {
   
    width: 340px;
    height: 200px;
  }
}

@media screen and (max-width: 375px) {
 

}
