.locationhead {
    margin-top:26px;
text-align: center;
font: normal normal 600 30px/20px Lato;
letter-spacing: 0px;
color: #553A28;
opacity: 1;
padding-bottom:10px;
}
.switchbtn {
    margin-bottom: 20px;
}
.savetext {
    margin-top: 15px;
    text-align: center !important;
    font: normal normal bold 14px/17px Lato;
    letter-spacing: 2.1px;
    color: #553A28;
    text-transform: uppercase;
    opacity: 1;
    /* margin-bottom: 10px; */
}
.fa-map-o {
    font-size: 32px;
    color: #553A28;
    opacity: 1;
    float:left;
    margin-right:20px;
    margin-left:23px;
    }
.currentlocationi {
    margin-top:31px;
}
.currentlocationi p {
    margin-top: 5px;
    font: normal normal normal 14px/20px Lato;
    letter-spacing: 0px;
    color: #2C2C2C;
    text-align: start !important;
}
.locationbtn {
    margin-top: 17px;
 
    border-radius: 4px;
    opacity: 1;
    padding-top:9px;
    padding-bottom: 9px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font: normal normal 600 14px/17px Lato;
    background: #4D3A21 0% 0% no-repeat padding-box;
    letter-spacing: 2.1px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-right:1px;
}
.storebtn {
    margin-top: 17px;
    padding-top:9px;
    padding-bottom: 9px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font: normal normal 600 14px/17px Lato;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    letter-spacing: 2.1px;
    border: 2px solid black;
    border-radius: 4px;
    text-transform: uppercase;
    opacity: 1;
}
.savedaddress {
    text-align: start;
}
.currentlocationi h6 {
    text-align: start;
}
.switchmodalfooter { 
    text-align: center !important;
    font: normal normal bold 14px/20px Lato;
    letter-spacing: 1.4px;
    color: #E5AB4E !important;
    opacity: 1;
    margin-top: 20px !important;
}
.switchfooter {
    text-align: center;
}
.closebtn {
    margin-top:20px;
    margin-right:20px;
    float:right;
    background-color: lightgrey;
    color:#553A28;
    width: 28px;
    height:28px;
    text-align: center;
    border-radius: 14px;
}

.primaryModal {
    position: absolute;
    top:5%;
    left: 35%;
    transform: translate(-50%, -50%) !important;
}