.freebiepara {
    text-align: center;
}

.frb-root{
	flex-direction: column;
	height: 60vh;
	width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width:none;
	scroll-behavior: smooth;
}

.frb-root::-webkit-scrollbar{
display: none;
}

.frb-card {
  min-height: 130px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 3px #ECEEF4C4;
  display: flex;
  overflow: hidden;
  margin:20px 8px 20px 8px;
 
}

.frb-card-details {
  text-align: left;
  padding: 10px;
  width:100%;
   position: relative;
}

.frb-img{
  width:110px;
object-fit: cover;
  height:auto;
}

.frb-btn {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
   justify-content: space-between;
  /* display: flex; */
  /* margin-top: auto;
  margin-bottom: 0px; */
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.frb-btn button{
  background: #E5AB4E;
    border: none;
    color: #fff;
    width: 110px;
    height: 30px;
    border-radius: 4px;
    padding: 2px 15px;
}
