@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

.locationmodal-text {
    font-size: 14px !important;
    text-align: start !important;

}
.input-fr {
    background: #F0F1F2 !important;
    border-radius: 6px;
    padding: 10px;
}
.yourlocation {
    text-align: center !important;
    font-size: 14px !important;
}

.cart-list{
    display: flex;
    flex-direction: column;
    /* border-radius: 0px 0px 6px 6px; */
    background-color: #fff;
    /* padding:20px; */
    min-height:100px;
    max-height: 400px;
    width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width:none;
	scroll-behavior: smooth;
}

/* .cart-list::-webkit-scrollbar{
display: none;
} */
body {
	font-family: 'Lato', sans-serif;
	background-color: #FAFBFC;
}
ul {
	margin: 0;
	padding: 0;
}
h2 {
    color: #553A28;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
}
.rdt  {
    border:1px#000 !important;
}
.about-welcome-left p {

text-align: left;
font: normal normal medium 18px/24px Lato;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
    color: #2C2C2C;
    font-size: 18px;
    line-height: 24px;
    max-width: 513px;
    padding-bottom: 30px;
    margin: 0;
}
.about-welcome {
    padding: 25px 0 41px;
    padding-bottom: 0px !important;
}
.about-welcome-left ul li {
    list-style-type: none;
    display: inline-block;
    color: #553A28;
    font-size: 18px;
    line-height: 22px;
    padding: 0 0px 0px 15px;
    text-align: center;
}
.about-welcome-left ul li:first-child {
    padding-left: 0;
}
.about-welcome-left ul li img {
    max-width: 30px;
    margin: 0 auto;
    margin-bottom: 10px;
}
.about-welcome-left {
    background-color: #fff;
    padding: 30px 15px;
}
.padding-right {
    padding-right: 0;
}
.padding-left {
    padding-left: 0;
}
.about-welcome-right img {
    height: 100% !important;
    width: 100%;
    object-fit: contain;
}
.about-welcome-left ul {
    padding-top: 20px;
}
.accounttext-fr {
    text-align: start !important;
    font-size: 13px !important;
    color: #2c2c2c;
    font-weight: 500 !important;
}
.about-welcome-left h3 {
    color: #553A28;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin: 0;
}
.about-services-main {
	text-align: center;
}
.about-services-main p {
	font-size: 14px;
    color: #2C2C2C;
    line-height: 21px;
    max-width: 220px;
    margin: 0 auto;
}
.about-services-main h2 {
    padding-top: 20px;
}
.about-founder {
    padding: 35px 0;
}
.about-services {
    background: #FAFBFC;
    padding: 0px 0px 30px;
}
.founder-description h5 {
    font-size: 16px;
    line-height: 21px;
    color: #553A28;
    font-weight: 700;
    margin: 0;
    padding-bottom: 5px;
}
.founder-description p {
    font-size: 14px;
    color: #2C2C2C;
    line-height: 21px;
    margin: 0;
}
.founder-description {
    padding-bottom: 15px;
}
.about-founder-text {
	border-radius: 4px 0px 4px 4px;
	background-color: #fff;
	position: absolute;
	top: auto;
	bottom: -42px;
	
	padding: 17px;
	left: 75px;
    width: fit-content;
    right: -30px ;
}
.about-founder-text h3 {
    color: #553A28;
    font-size: 22px;
    line-height: 29px;
    text-transform: uppercase;
    margin: 0;
}
.about-founder-text p {
    margin: 0;
    font-size: 19px;
    line-height: 21px;
    color: #2C2C2C;
}
.about-founder-left {
    position: relative;
}
.map-locaion {
	text-align: center;
	/* padding: 65px 0px; */
    background-color: white;
    margin-bottom: 65px !important;
    margin-top: 65px !important;
}
.map-locaion h2 {
	text-align: center;
    width: 100%;
    margin: 0;
    padding-bottom: 30px;
}
.our-kitchens-left p {
	font-size: 14px;
	line-height: 21px;
	color: #2C2C2C;
	margin-bottom: 2px;
}
.our-kitchens-left img {
    margin-top: 25px;
}
.award-slider {
    padding: 65px 0;
}
.award-slider h2 {
    width: 100%;
    text-align: center;
}
/*checkout css*/
section.checkout {
    margin-bottom: 2rem;
    margin-top:26px;
    margin-left:20px;
    margin-right:20px;
}
.selectedaddress-fr-text {
    color: #93959f !important;
    max-width: 480px;
    line-height: 18px;
    text-align: start;
    font-size: 15px !important;
}
.etaaddress-fr {
    color: #282c3f;
    font-size: 13px !important;
    font-weight: 500 !important;
    margin-top: 15px !important;
}
.fr-change {
    border: none !important;
    background: #fff !important;
    font-size: 15px !important;
    font-weight: 600;
    letter-spacing: 1.2px !important;
    text-transform: uppercase;
    right: 30px !important;
    position: absolute;
    color: #553A28 !important;
}
.changebtn-fr {
    float: right;
    border: none !important;
    background: #fff !important;
    font-size: 15px !important;
    font-weight: 600;
    letter-spacing: 1.2px !important;
    text-transform: uppercase;
    right: 30px !important;
    position: absolute;
    color: #553A28 !important;
}
.hometext-fr {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 500;
    color: #282c3f;
    text-transform: capitalize;
    color: #553A28;
}
.homedeliver {
    text-transform: none !important;
}
.homeeta  {
    margin-top: 0px !important;
}
.checkout {
    /* margin-top:103px; */
    margin-left:20px;
    margin-right:20px;
}
.deliveryaddress-fr {
    padding-right: 15px !important;
}
.commentsOrder {
    border-radius: 6px;
    margin-top: 30px;
    width: 350px;
    padding:6px 20px;
    background-color:#e9ecee;
    border: none !important;
}
.checkout-left {
    border-radius: 0px 0px 6px 6px;
    background-color:transparent !important;
    padding: 0px !important;
    min-height: 100%;
}
.personaldetails {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 41px;
}
.namepay {
    font: normal normal 500 18px/18px Lato !important;
}
.namepay::after {
    content: "|";
    padding: 0 8px;
}
.cartarrow {
    width: 28px;
    height: 28px;
    float: right;
    margin-left: 10px;
}
.cartproceed {
    font: normal normal bold 20px/24px Lato;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.ordermodedropdown-fr {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 41px;
}
.selectedorderbody {
    background-color: #fff;
}

.comments-order {
    padding-top: 30px;
}
.choosepaymentorder-fr {
    background-color: #fff;
    padding: 26px;
}
.add-address {
    margin-top: 20px;
}
.add-address:hover {
    box-shadow: 0 0 7px 4px #eceef4c4;
}
.checkout-left h2 {
    margin-top: 13px;
margin-left: 33px;
text-align: left;
font: normal normal 600 20px/24px Lato;
letter-spacing: 0px;
color: #553A28;
opacity: 1;
    text-transform: capitalize;
    margin: 0;
    padding-bottom: 45px;
}
.delivery-address {
  border: 1.5px solid #F5F5F5;
  border-radius: 6px;
	padding: 15px;
	display: flex;
  margin:8px;
}
.delivery-address:hover{
  border-radius: 6px;
  border: 1.5px solid #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}
.delivery-address h4 {
	font-size: 16px;
    line-height: 20px;
    color: #553A28;
    font-weight: bold;
    margin: 0;
}
.delivery-address p {
	font-size: 14px;
	line-height: 18px;
	color: #2C2C2C;
	opacity: 67%;
	margin: 0;
	padding: 10px 0;
    text-align: start;
}
.delivery-address h5 {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    color: #2C2C2C;
}
button.btn.delivery-btn {
    border: 1px solid #553A28;
    border-radius: 4px;
    width: 100%;
    color: #553A28;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    font-weight: bold;
    margin-top: 10px;
    padding: 7px 15px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}
button.btn.delivery-btn:hover {
    background-color: #553A28;
    color: #fff;
}
button.btn.delivery-btn:focus {
    outline: none;
    box-shadow: inherit;
}
.delivery-text {
    padding-left: 25px;
}
.add-address {
	border: 2px solid #F5F5F5;
	border-radius: 4px;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}
.add-address p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    color: #E5AB4E;
    margin: 0;
    font-weight: bold;
    padding-top: 20px;
}	
.checkout-left hr {
    border-color: #F5F5F5;
    margin: 30px 0;
}
.choose-payment h2 {
    margin: 0;
    padding-left: 10px !important;
}
.new-fr-frr {
    letter-spacing: 0px !important;
    color: #000 !important;
    opacity: 1 !important;
    font-size: 16px;
}
.fr-checkout-price-tag {
    padding-left: 10px !important;
}
.choose-payment .nav-pills {
    background-color: #F0F1F2;
	border-radius: 4px;
}
.choose-payment .nav-pills .nav-link.active {
    color: #2C2C2C;
    background-color: #fff;
}
.choose-payment .nav-pills .nav-link {
	font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    color: #2C2C2C;
    padding: 10px 5px;
    border-radius: 4px;
    margin-left: 10px;
}
.tab-content {
    margin-left:56px;
}
.checkout-right .lore {
    padding-bottom:20px;
}
.tab-card {
	background-color: #004790;
	border-radius: 4px;
	padding: 13px 15px;
	max-width: 384px;
	justify-content: space-between;
    display: flex !important;
    
}
.tab-card span {
	color: #fff;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}
.add-new-card {
    border: 1px solid #F5F5F5;
    border-radius: 4px;
    max-width: 384px;
    margin-top: 30px;
    padding: 13px 15px;
    display: flex;
}
.add-new-card-text h5 {
    margin: 0;
    color: #2C2C2C;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
}
.add-new-card-text p {
    margin: 0;
    color: #2C2C2C;
    font-size: 12px;
    line-height: 15px;
    opacity: 67%;
}
span.plus-card-item {
    width: 37px;
    height: 22px;
    border: 1px solid #553A28;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
.add-new-card-text {
    padding: 0 20px;
}
.add-new-card-text ul li {
    list-style-type: none;
    display: inline-block;
}

.checkout-right {
    border-radius: 0px 0px 6px 6px;
    background-color: #fff;
}
.main-quantity .col-md-4 {
    padding:0px !important
}
.quantity-image img {
    border-radius: 4px;
}
.quantity-text h4 {
    margin: 0;
    color: #553A28;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 10px;
}
.quantity-text p {
    color: #2C2C2C;
    font-size: 18px !important;
    line-height: 20px !important;
    margin: 0;
    font-weight: 700;
    padding-top: 0px !important;
}
.quantit-item {
    position: absolute;
    right: 0;
}
.quantit-item span img
.quantit-item span {
    margin-right:0px !important;
    padding-left: 10px;
    background-color: #FAFBFC;
    border-radius: 4px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
    color: #5CB346;
}
.quantity-text {
    padding-left: 20px;
}
.main-quantity {
    display: flex;
    position: relative;
}
.checkout-right p {
    margin: 0;
    text-align: start;
}
.checkout-right hr {
    border-color: #F5F5F5;
}

.coupon-text p {
    color: #2C2C2C;
    font-size: 16px;
    margin: 0;
    line-height: 20px;
    font-weight: 700;
    padding: 0;
    padding-left: 5px;
}
.coupon-list {
    border: 2px solid #F5F5F5;
    display: flex;
    padding: 10px;
    position: relative;
    align-items: center;
}
.coupon-number span {
	border: 1px solid #D0973C;
    background-color: #E5AB4E;
    width: 95px;
    height: 24px;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0px;
    position: absolute;
    right: 12px;
    top: 16px;
}
.coupon-list.coupon-border {
    border-top: 0;
    border-bottom: 0;
}
.item-total {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}
.totalorder {
    float: right !important;
}
.item-total p {
    padding: 0;
    font-size: 16px;
    color: #748090;
    line-height: 20px;
    /* opacity: 67%; */
    font-weight: 500;
}
.timesarray {
    color:#000 !important;
    background-color: white!important;
}
.main-item-total {
    padding-top: 20px;
}
.main-item-total .item-total:nth-child(2) {
    padding-bottom: 0px;
}
.pay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    box-shadow: 0 0 7px 4px #ECEEF4C4;
    padding: 15px 20px;
}
.pay-fr {
     display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
}
.pay h6 {
    margin-bottom: 0;
    color: #2C2C2C;
}
.accountlogin {
    text-align: center; 
    padding: 7px 38px 9px;
    line-height: 1.2;
    cursor: pointer;
    color:#553A28;
    background-color: #FFF;
    margin-right: 20px !important;
    border: 1px #553A28 !important;
    outline: auto;
}
.accountlogin:hover {
    box-shadow: 0 4px 14px #d4d5d9;
}
.notloginorder {
    color: rgba(85, 58, 40,0.6) !important;
}
.top-float-bar {
    display: none;
}
.accountregister {
    text-align: center;
    padding: 8px 35px 9px;
    line-height: 1.2;
    cursor: pointer;
    color:#FFF;
    background-color: #553A28 ;
    /* margin-right: 20px !important; */
    border: 1px #553A28 !important;
    outline: auto;
    border-radius: 6px !important;
}
.accountregister:hover {
    box-shadow: 0 4px 14px #d4d5d9;
}


/*checkout css*/
@media only screen and (max-width: 1024px) {
	.about-founder-text {
	    padding: 12px;
    }
    .choose-payment .tab-content{
        margin-top: 1rem;
    }
}
@media only screen and (max-width: 991px) {
	.about-founder-text {
	    position: sticky;
	    margin: 15px 0;
	}
   

}
@media only screen and (max-width: 768px) {
    .paymentmain {
        display: flex;
        flex-direction: column-reverse;
    }
    .theme-v-tabs .theme-vtab-content {
        flex: 2 1 !important;
        padding: 25px 1rem;
        background-color: #FFFFFF;
    }
    .cardinputs {
        display: flex;
        flex-direction: column !important;
    }
    .theme-vtab-content {
        padding-top: 0px !important;
    }
    .new-fr-frr {
        color: black !important;
    font-size: 10px;
    text-align: start;
    font-family: 'Lato' !important;
    }
    /* .input-fr {
        outline: auto !important;
    } */
    .top-float-bar {
       display: block;
    /* top: 0px; */
    left: 0px;
    padding-left: 18px;
    padding-top: 20px;
    opacity: 1;
    background: 0px center #FFFFFF;
    top: 60px;
    position: fixed;
    z-index: 1;
    justify-content: space-between;
    box-shadow: 0px 5px 7px #00000021;
    width: -webkit-fill-available;
    height: 65px !important;
    }
    .modelForm-fr {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 10px;
    }
}
@media only screen and (max-width: 767px) {
	.padding-left {
	    padding-left: 15px;
	}
	.padding-right {
	    padding-right: 15px;
	}
	.about-welcome {
	    padding: 25px 0 40px;
	}
	.about-services .col-lg-4.col-md-4:nth-child(2) .about-services-main {
	    padding: 25px 0;
	}
	.our-kitchens-right {
	    padding-top: 30px;
    }    
    .checkout-right,
    .add-address {
        margin-top:0px;
    }
    .notebox {
       margin-top: 20px;
        margin-right: 0px !important;
    }
.paymentmain {
    display: flex;
    flex-direction: column-reverse;
}
.checkout-left {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.quantit-item span {
    position: absolute;
    right: 0px !important;
}
.commentsOrder {
    width: 360px;
}
.modelForm-fr {
    display: grid;
    grid-template-columns: 1fr !important;
    margin-top: 20px;
    margin: 20px;
}
.nametext {
    width: 100%;
    border: 1px !important;
}
.name-fr {
    border: auto !important;
    min-height: fit-content !important;
}
.new-fr-frr {
    font-size: 13px !important;
}
.choosepaymentorder-fr  {
    padding: 0px !important;
    padding-top: 26px !important;
}
.new-pay-fr {
    padding-left: 15px !important;
}
.personaldetails {
    margin-top: 20px;
}
}
@media only screen and (max-width: 450px)  {
    .commentsOrder {
        width: 290px;
    }
    .totalorder {
        margin-top: 10px;
        float: left !important;
    }
    .new-fr-frr {
        font-size: 12px !important;
    }
    .choosepaymentorder-fr  {
        padding: 0px !important;
        padding-top: 26px !important;
    }
    .new-pay-fr {
        padding-left: 20px !important;
    }
    .notebox {
        width: 280px !important;
    }
    .css-2b097c-container {
        width: 150px;
    }
    .quantity-text h4 {
        padding-top: 10px !important;
    }
    .dropdowndatetime {
        display: flex;
        width: 320px !important;
    }
    .ordermodedropdown-fr {
        padding: 11px !important;
    }
    .delivery-address {
        margin-top: 20px;
    }
    .personaldetails {
        margin-top: 20px;
        padding: 21px!important;
    }
    /* .choose-payment h2 {
        padding-left: 10px;
    } */
    .cartorder {
        width: 280px !important;
    }
    .tiemsot {
        padding-right: 10px;
    }
    .new-pay-fr {
        padding-left: 10px !important;
        padding-bottom: 45px !important;
        margin-bottom: 41px;
    }
    .add-address {
        margin-top: 20px !important ;
    }
}
@media only screen and (max-width: 375px)  {

    .new-pay-fr {
        margin-bottom: 41px;
        padding-bottom: 45px !important;
    }
    .totalorder {
        margin-top: 10px;
        float: left !important;
    }
    .choose-payment {
        margin-top: 20px !important;
    }
    .personaldetails {
        margin-top: 20px;
        padding: 21px!important;
    }
    .add-new-card-button {
        margin-top: 20px !important;
    }
    .notloginorder {
        padding-top: 20px !important;
    }
    .quantity-text {
        padding-left: 10px !important;
    }
    .quantity-image {
        height:67px;
        padding-left: 10px !important;
    }
    .add-address {
        margin-top: 20px !important ;
    }
}
