.imgpad {
  overflow: hidden;
  max-height: 300px;
  margin-right: 0;
  border-radius: 6px;
  position: relative;
  padding-right: 20px;
}
.imgpad img {
  height: 100%;
  width: auto;
}
.imgpad h2,
.imgpad h3 {
  position: absolute;
  bottom: 15px;
  left: 25px;
  color: #fff;
}
.imgpad h2 {
  bottom: 50px;
  font-weight: 600;
}

.slick-dots li button:before {
  color: #E5AB4E;
  font-size: 6px;
  opacity: .4;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #E5AB4E;
  background: transparent url('../../../images/Rectangle\ 16060.svg') 0% 0% no-repeat padding-box !important;
  background-position: center !important;
  background-size: contain !important;
font-size: 0 !important;
opacity: 1;
}
.slick-slide.slick-active:last-child div {
  margin-right: 0px;
}
.slick-track {
  width:max-content !important;
  margin-left: 0;
}
.bigsaving-fr {
  padding-left:10px !important;
}
.slick-slide {
  object-fit: cover !important;
}
a{
  color:#553A28;
  
}
a:hover{
  color:#553A28;
  text-decoration: none;
}


