.panel-card {
  box-shadow: 0px 0px 20px #0000000D;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
}

.arivaltime {
  font-size: 18px !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
}

.takeaway-btn {
  background-color: #553A28;
  color: #fff;
  padding: 5px 30px;
  border-radius: 6px;
}

.choose-payment-hide {
  display: none;
}

.choose-order-proceed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.order-address-fr {
  /* margin-bottom: 20px !important; */
}

.choose-delivery {
  margin-bottom: 20px !important;
}

.newnotetext {
  margin-top: 10px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 15px;
  color: #495057 !important;
  background-color: #fff;
  text-align: justify;
}

.PhoneInput {
  display: flex;
  align-items: center;
}

.countryFlag {
  /* width: 100%; */
  /* position: absolute; */

  /* left: 12px; */
  /* top: 3px; */
  /* background-color: #F0F1F2 */
}

.countryFlag input {
  padding: 10px;
  min-height: 50px;
  width: 100%;
  height: 100%;
  border: 0px;
  border-radius: 6px;
  background-color: #F0F1F2;
}

.countryFlag input:focus {
  outline: none;
}

.countryFlag .PhoneInputCountryIcon {
  border-radius: 50%;
  overflow: hidden;
  width: 45px;
  height: 45px;
  border: none;
  box-shadow: none;
}

@media only screen and (max-width: 768px) {
  .paymenttag {
    display: flex;
    flex-direction: column !important;
    padding-left: 10px;
    padding-bottom: 10px;
  }

  .panel-card-fr {
    padding-left: 0px !important;
  }

  .panel-card {
    padding-bottom: 41px !important;
  }

  .choose-order-proceed {
    display: flex;
    flex-direction: column;
  }

  .order-total {
    padding: 10px;
  }

  .your-detail-mobile {
    margin-top: 0px !important;
  }
}

.guest-detail-mobile {
  box-shadow: 0px 0px 20px #0000000D;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  width: '100%'
}