.cp-item-total {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}
.cp-item-total p {
    padding: 0;
    font-size: 16px;
    color: #2C2C2C;
    line-height: 20px;
    /* opacity: 67%; */
    font-weight: 500;
}
.coupon-list {
  margin-top: 30px;
  border: 1px solid #F5F5F5;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  /* position: relative; */
  /* align-items: center; */
}
.loyalty-check {
  border-bottom: 1px solid #F5F5F5;
  display: flex;
  padding: 10px ;
  position: relative;
  align-items: center;
  width: 100%;
}

.freebie-btn{
    border-radius: 3px;
    width: 95px;
    height: 24px;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0px;
    position: absolute;
    right: 12px;
    top: 16px;
    cursor: pointer;
}