.odr-modalHeading-track {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    margin-bottom: 20px;
    margin-top: 20px;
}

.odr-trackheader {
    font-size: 20px !important;
    color:#553A28;
    text-transform: none !important;
}

.odr-trackmodalcontent {
    width: 223px;
    font-size: 14px !important;
    text-align: start;
    margin-bottom: 10px !important;
}

.odr-modelMap {
  margin: 0 -26px;
 }

.odr-trackmapstatus {
    border-radius: 27px 27px 0px 0px;
}

.odr-lesstext {
    display: 'flex';
    margin-right: 10px; 
    width: 100%;
    min-height: 50px;
    max-height: 188px; 
    flex-direction: column;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: auto;
}

.odr-main-item-total {
    padding-top: 0px;
    padding-bottom: 0px;

}
/* .odr-main-item-total .odr-item-total:nth-child(2) {
    padding-bottom: 0px;
} */

.odr-item-total {
    display: flex;
    justify-content: space-between;
    /* padding-bottom: 10px; */
}

.odr-item-total p {
    padding: 0;
    font-size: 16px;
    color: #748090;
    line-height: 20px;
    /* opacity: 67%; */
    font-weight: 500;
}

.odr-pay-fr {
     display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
}