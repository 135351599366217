
/* Reward */
.goldTierCard {
    background: linear-gradient(#A42500, #932100);
    padding: 15px;
    border-radius: 6px;
    color: #fff;
    position: relative;
    height:200px;
}
.goldCardHead {
    text-transform: uppercase;
    color: #FDC66D;
    font-size: 13px;
    display: flex;
    align-items: center;
    outline: none;
}


.marBot30 {
height: 24px;
text-align: left;
font: normal normal bold 20px/34px Lato;
letter-spacing: 3px;
color: #E5AB4E;
text-transform: uppercase;
opacity: 1;
}
.cardGrid {
    margin-top: 12px;
margin-left: 13px;
width: 17px;
height: 19px;
background: transparent no-repeat padding-box;
opacity: 1;
    position: absolute;
    right: 15px;
}
.goldCardHead img {
    margin-right: 6px;
}
.rewardPoints {
    display: flex;
    align-items: center;
    padding: 20px 0 10px;
    border-bottom: solid 2px #7E1D00;
}
.rewardPoints h3 {
    font-size: 20px;
    margin-bottom: 4px;
    font-weight: 500;
}
.goldCardFooter {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
    text-align: center;
}
.goldCardFooter h2,
.currentPoint h2 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 2px;
}
.goldCardFooter p,
.currentPoint p,
.proCategory p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.currentConversion {
    background: linear-gradient(45deg, #3dbc9c, #33a084);
    padding: 15px;
    border-radius: 6px;
    margin-top: 30px;
    text-align: center;
    height:130px;
}
.currentPoint {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 17px 0 15px;
    color:#FFFFFF
}
.progressSection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 17px 0 15px;

}
p {
text-align: left;
font: normal normal normal 10px/17px Lato;
letter-spacing: 0px;
color: #FFFFFF !important;
text-align: center;
font-weight: 100;
opacity: 0.86;
}

.currentPoint .equal {
    margin: 0 23px;
    font-size: 24px;
}
.currentConversion p {
    text-transform: uppercase;
}
.currentPoint small {
    font-size: 12px;
    margin-bottom: 9px;
}
.currentConversion p  {
    color: #FFFFFF !important;
}
.newp {
     font: normal normal 600 12px/15px Lato !important;
letter-spacing: 1.8px !important;
color: #FFFFFF !important;
text-transform: uppercase ;
opacity: 0.86 !important;
}
.newtwo {
    font: normal normal 600 14px/17px Lato !important;
letter-spacing: 2.1px !important;
color: #FFFFFF !important;
text-transform: uppercase;
text-align: center !important;
opacity: 1;
}
.newsmall {
    font: normal normal 600 12px/15px Lato !important;
letter-spacing: 1.8px !important;
color: #FFFFFF !important;
text-transform: uppercase !important;
opacity: 0.5 !important;
}
.new-fr {
    letter-spacing: 0px !important;
color: #FFFFFF !important;
opacity: 1 !important;
}
.tierProgress {
    background: linear-gradient(45deg, #D9A248, #FDC66D);
    border-radius: 6px;
    color: #fff;
    margin-top: 30px;
    text-align: center;
    margin-bottom:28px;
}
.tierProgress p {
    padding-top:9px;
text-align: center;
font: normal normal 600 14px/17px Lato;
letter-spacing: 2.1px;
color: #FFFFFF;
text-transform: uppercase;
opacity: 1;
}
.proCategory img {
width: 35px;
background: transparent 0% 0% no-repeat padding-box;
opacity: 1;
}
.progress {
    width: 165px;
    height: 34px;
    background: transparent !important;
    overflow: visible;
    border-radius: 5px;
    margin-bottom: 23px;
    margin-left:10px;
    margin-right: 29px !important;
}
.progress-bar {
    border-radius: 5px;
    width: 50%;
    display: contents !important;
    background-color: transparent !important;
}
.progressUser {
    width: 28px;
height: 30px;
right:-5px;
background: transparent  0% 0% no-repeat padding-box;
opacity:1;
}

.modalProduct {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 7px 4px #ECEEF4C4;
    margin-top: 30px;
    display: flex;
    overflow: hidden;
}

.modalProductImg {
    width: 150px;
}

.modalProductImg img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.modalProductDetails {
    text-align: left;
    padding: 10px;
}

.modalProductDetails h5 {
    font-size: 18px;
    color: #553A28;
    margin-bottom: 5px;
}

.modalProductDetails p {
    font-size: 14px;
}

.modalProductDetails .modalProductFooter {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalProductFooter button {
    background: #E5AB4E;
    border: none;
    color: #fff;
    border-radius: 4px;
    padding: 2px 15px;
}

.modalProductFooter span {
    font-size: 14px;
    font-weight: 600;
}
.modalProduct.birthDayOffer {
    background: #A74F20;
    color: #fff;
}
.modalProduct.birthDayOffer {
    background: #A74F20;
    color: #fff;
}
.modalProduct.birthDayOffer h5 {
    color: #fff;
}
.modalProduct.birthDayOffer button {
    background: #9A471C;
}
.rewardViewAll {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rewardViewAll button {
height: 17px;
text-align: right;
font: normal normal bold 14px/17px Lato;
letter-spacing: 1.4px;
color: #2C2C2C;
text-transform: uppercase;
opacity: 1;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
}
/* Reward */

.birthDayOffer {
    margin-top:51px !important;
}
.birthdayimg {
    height:150px !important;
}
@media only screen and (max-width: 767px) {
    .rewardViewAll {
        margin-top: 2rem;
    }
    .birthDayOffer {
        margin-top:30px !important;
    }
}
