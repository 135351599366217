.tip-label{
  align-self: flex-start;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #41434A;
  text-transform: capitalize;
  opacity: 1;
}

.tip-heading{
  align-self: flex-start;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 0;
  font-size: 16px;
  color: #2C2C2C;
  line-height: 20px;
  font-weight: 500;
}


.tip-label-bold{
  margin-top: 5px;
  margin-bottom: 5px;
  font: normal normal bold 14px/19px Open Sans;
  letter-spacing: 0px;
  /* color: #41434A; */
  opacity: 1;
}

.tip-input{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 10px;
  margin-right: 10px;
  /* margin-left: 20px; */
  font-size: 18px;
  font-family: 'Open Sans';
  height: 35px;
  width: -webkit-fill-available;
  text-align: center;
  background: #F0F1F2 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border-width: 0px;
  margin-top: 10;
}

.tip-warning-label{
  align-self: center;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #D53A24;
  /* text-transform: capitalize; */
  opacity: 1;
}

.tip-custom-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  width: -webkit-fill-available;
}