.heading-section {
  flex-direction: row;
}

.heading-title {
  text-transform: uppercase;
  font-size: 20px;
  margin-right: 7px;
  color: #E5AB4E;
  letter-spacing: 3px;
}
.reedem-button-small {
  background-color: #E5AB4E;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 4px;
  padding: 5px 15px;
  font-weight: 600;
}
.reedem-button-small > img {
  display: none;
}
.header-button{
  color: #2C2C2C;
  font-size: 14px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-weight: bold;
}
.redeem-button-small-div{
  justify-content: flex-start;
}
.exclusive-heading{
  display: none;
}
.tier-progress{
  background: linear-gradient(254deg,#FDC66D,#E5AB4E,#D9A248);
}
.benefit-item{
  background-color: #fff;
  box-shadow: 0 0 10px 0px #ECEEF4C4;
}
.current-card-footer{
  background-color: unset;
  border-radius: unset;
  border-top: 2px solid #7E1D00;
  padding: unset;
  padding-top: 12px;
  margin-top: 10px;
}
.tier-progress-bar{
background-color: #0FA084;
}
.current-card{
  box-shadow: unset !important;
}
.current-card-dots{
  display: block;
}
.conversion-card{
  background: linear-gradient(90deg, #3dbc9c, #33a084);
}

.benefit-points-icon {
  content: url(./loyaltyPoint.png);
}
.terms-link{
  color: blue;
}
.terms-check-box {
  border-style: groove;
  width: 20px;
  height: 20px;
  color: black;
}