.freebiepara {
    text-align: center;
	font-size: 14px !important;
}

.addon-modal-body {
	position: relative;
	padding: 15px !important;
}

.addon-item {
  /* border-radius: 6px; */
  box-shadow: 0 4px 10px 2px #ECEEF4C4;
  margin-top: 20px;
	margin: 8px;
}
.addon-item-fr {
  border-radius: 6px;
  box-shadow: 0 4px 10px 2px #ECEEF4C4;
  margin-top: 0px;

}
.menuanimation {
  /* animation: fadeIn ease 6s; */
}


.addon-root{
     height: 350px;
	width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width:thin;
	scroll-behavior: smooth;
    padding: 0px;
     overflow: auto;
  }

/* .addon-root::-webkit-scrollbar{
display: none;
} */

.ad-card{
    display: flex;
	cursor: pointer;
	height: fit-content;
  
}

.alert-anim{
	color: #FAFBFC;
	background-color: #CE4632;
	position: absolute;
	bottom: 50px;
	padding: 6px;
	font-size: 13px;
	width: 87% ;
	border-width: 0px;
	animation: mymove 0.3s ;
}
@keyframes mymove {
  from {bottom: 40px;}
  to {bottom: 50px;}
}

.checkbox-compatible{
    -webkit-appearance: checkbox;
}