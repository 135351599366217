.modalErrors.worng .errorHeading {
  color: #D84132;
}
.modalErrors.worng .errorText {
  max-width: 255px;
  margin: auto;
}
.modalErrors.worng button {
  background: #fff;
  color: #4D3A21;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  border: solid 1px #553A28;
}
.modalErrors.worng {
  width: 100%;
}