.manageaddressp {
    float: right;
    color:#553A28 !important;
    font-size: 24px;
    letter-spacing: 1.4px;
    line-height: 17px;
    font-weight: bold;
}
.fa-map-marker {
    font-size: 34px;
    color: #553A28;

}
.fa-pencil {
    color:#553A28 !important;
    padding-left:50px;

}
.addaddressbtn{
    color:#553A28;
    border:2px solid #553A28 !important;
    background-color: #FFFFFF;
    border-radius:4px;
    padding-left:100px;
    padding-right: 100px;
}