.carousel-indicators {
  bottom:-50px;
  /* color: blue; */
}

.carousel-inner {
  margin-bottom:50px;
}

.carousel-indicators li {
  border-top: unset;
  width: 10px;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color:#E5AB4E;
  opacity: 0.5;
  height: 10px;
  width: 10px;}                                                  
                                                                 
  .carousel-indicators .active {
  border-top: unset;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 10px;
  background-color: #E5AB4E;
  height: 10px;
  width: 50px
}


.carousel-control-next {
  display: none;
}

.carousel-control-prev {
  display: none;
}

.carousel-item{
  border-radius: 6px;
}