.alert-toast-anim{
	color: #FAFBFC;
	background-color: #CE4632;
	position: absolute;
	bottom: 50px;
	padding: 6px;
	font-size: 13px;
	width: 350px;
	border-width: 0px;
	animation: mymove 0.5s ;
  z-index: 3;
}
@keyframes mymove {
  from {bottom: 40px;}
  to {bottom: 50px;}
}