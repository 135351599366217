.heading-section {
    flex-direction: row;
}

.heading-title {
    text-transform: uppercase;
    font-size: 20px;
    margin-right: 7px;
    color: #E5AB4E;
    letter-spacing: 3px;
}
.milestone-1 p{
    color: #fff !important;
}
.milestone-2 p{
    color: #fff !important;
}
.milestone-3 p{
    color: #fff !important;
}
.milestone-4 p{
    color: #fff !important;
}
.program-content{

}
.your-progress-div{
    background: transparent linear-gradient(267deg, #122465 0%, #324c9c 100%) 0% 0% no-repeat padding-box;
}