.bannerimg-fr {
  height: 261px !important;
  object-fit: contain;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.appDetails {
    margin-top: 38px;
    padding: 25px;
    background: transparent linear-gradient(180deg, #FFF8ED 0%, #F8F2E7 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px !important;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0px 0px 25px #E7EAF0 !important;
    padding-bottom: 12px !important;
}
.appDetails h2 {
    font-size: 35px;
    text-transform: capitalize;
    font-weight: 400;
}
.appnewrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.appLinkButtons{
  align-items: flex-start;
  margin-bottom: 20px;
}

.appnewh2 {
    text-align: left;
    font: normal normal normal 35px/42px Lato !important;
    letter-spacing: 0px;
    font-weight: normal !important;
    color: #2C2C2C;
    font-family: 'Lato' !important;
    text-transform: none;
}
.appnewspan {
    font: normal normal 600 35px/42px Lato;
    letter-spacing: 0px;
    color: #2C2C2C;
    font-weight: 600 !important;
    text-transform: capitalize;
    font-family: 'Lato' !important;
}
.appnewtext {
    letter-spacing: 0px;
    color: #2C2C2C;
    font-weight: bold !important;
    font-size: 35px !important;
    opacity: 1;
    line-height: 21px !important;
    font-family: 'Lato' !important;
    margin-bottom: 12px !important;
}
.appnewbody {
   
    margin-bottom: 27px !important;
}
.appnewdownloadtext {
  font: normal normal normal 17px/21px Lato;
  letter-spacing: 0px;
  color: #2C2C2C;
}
.appDetails h2 span {
    font-weight: 600;
}
.appDetails p {
    font-size: 17px;
    margin-top: 20px;
}

.input-group.mb-3 {
    position: relative;
}
.input-group-append {
    position: absolute;
    right: 0;
}
input.form-control {
    height: 45px;
    /* border: none; */
    border-radius: 6px 0 0 6px;
}
.input-group-append span {
    height: 45px;
    border-radius: 0 6px 6px 0;
    background: #553A28;
    border: none;
    color: #fff;
    padding: 0 18px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}
.MuiDialog-paperWidthSm{
    width: 573px!important;
    height: 416px !important;
    margin-left: auto !important;
    overflow-x: hidden;
}

  .appnewh2 {
    text-transform: none !important;
}

 .name-fr {
     font-size: 15px !important;
 }
 .switchto {
     margin-top: 10px !important;
 }
 .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}
 *:focus {
    outline: none !important;
}

 .veg-fr {
    text-align: center;
font: normal normal 600 12px/17px Lato; 
color: #2C2C2C;
text-transform: uppercase;
opacity: 1;
margin-right: 20px;
margin-left: 10px;
margin-top:20px;
}
.checkbox1{
    margin-top:20px !important;
}
.vegdiv {
    border: 1px solid #DFDFDF;
border-radius: 4px;
opacity: 1;
    padding:12px;
    margin-top:20px;
}
@media only screen and (max-width: 600px) {
 
   .appnewh2 {
    text-transform: none !important;
}
   .MuiDialog-paperWidthSm{

        overflow-x: hidden;
        margin-left:20px!important;
}
.appnewtext {
    font-size: 28px !important;
}
.MuiDialog-paperScrollPaper {
    overflow-x:hidden;
}
.vegonly {
    margin-top:20px !important;
}


}
@media only screen and (max-width: 400px) {
  
    /* .dropdown-menu {
        width: 360px !important;
    } */
    
    .MuiDialog-paperWidthSm{
        width: 370px!important;
        height: 316px !important;
        overflow-x: visible;
            overflow-y: hidden;
            margin-left:20px!important;
    }
    .MuiDialog-paperScrollPaper {
        overflow-y:hidden;
}
 .bannerimg-fr {
  height: 220px !important;
  object-fit: contain;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.appnewh2 {
    text-transform: none !important;
}
 
 }
 @media only screen and (max-width: 450px) {

  .appLinkButtons{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
 }

 @media only screen and (max-width: 525px) {
 
    .MuiDialog-paperWidthSm{
        margin-left:20px!important;
    }
    .MuiDialog-paperScrollPaper {
                overflow-y:hidden;
    }
 }
 .appnewh2 {
    text-transform: none !important;
}
 @media only screen and (max-width: 530px) {
    .MuiDialog-paperWidthSm{
        margin-left:20px!important;
        overflow-y: hidden;
    }
    .MuiDialog-paperScrollPaper {
        overflow-y:hidden;
}
.appnewh2 {
    text-transform: none !important;
}
 }