
.successModel .modal-content {
  position: relative;
  min-height: 500px;
  text-align: center;
}
.successModel .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.successBg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.modalErrors .errorHeading {
  color: #00A56D;
  font-size: 24px;
  font-weight: 600;
  margin: 15px 0 5px;
}
.modalErrors .errorText {
  color: #2C2C2C;
  font-size: 20px;
  opacity: 0.55;
}