.freebiepara {
    text-align: center;
	font-size: 14px !important;
}

.timings-modal-body {
	position: relative;
	padding: 15px !important;
}

.timings-item {
  /* border-radius: 6px; */
      display: grid;
    place-items: center;
  box-shadow: 0 4px 10px 2px #ECEEF4C4;
  margin-top: 20px;
	margin: 8px;
   padding:20px;
}
.timings-item-fr {
  border-radius: 6px;
  box-shadow: 0 4px 10px 2px #ECEEF4C4;
  margin-top: 0px;

}
.menuanimation {
  /* animation: fadeIn ease 6s; */
}


.ad-root{
     height: 350px;
	width: -webkit-fill-available;
	overflow-x: hidden;
	overflow-y: scroll;
	scrollbar-width:none;
	scroll-behavior: smooth;
    padding: 0px;
}

.ad-root::-webkit-scrollbar{
display: none;
}

.ad-card{
    display: flex;
	cursor: pointer;
	height: fit-content;
  
}

.alert-anim{
	color: #FAFBFC;
	background-color: #CE4632;
	position: absolute;
	bottom: 50px;
	padding: 6px;
	font-size: 13px;
	width: 87% ;
	border-width: 0px;
	animation: mymove 0.3s ;
}
@keyframes mymove {
  from {bottom: 40px;}
  to {bottom: 50px;}
}