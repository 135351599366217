@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
/* import FILE_PATH from '/utils/Constatnt/FILE_PATH'; */


body {
	font-family: 'Lato', sans-serif;
	background-color: #FAFBFC;
}

ul {
	margin: 0;
	padding: 0;
}

.modelForm-fr {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
h2 {
    color: #553A28;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
}
.about-welcome-left p {
    color: #2C2C2C;
    font-size: 18px;
    line-height: 24px;
    max-width: 513px;
    padding-bottom: 30px;
    margin: 0;
}
.about-welcome {
    padding: 25px 0 60px;
}
.about-welcome-left ul li {
    list-style-type: none;
    display: inline-block;
    color: #553A28;
    font-size: 18px;
    line-height: 22px;
    padding: 0 0px 0px 15px;
    text-align: center;
}
.about-welcome-left ul li:first-child {
    padding-left: 0;
}
.about-welcome-left ul li img {
    max-width: 30px;
    margin: 0 auto;
    margin-bottom: 10px;
}
.about-welcome-left {
    background-color: #fff;
    padding: 30px 15px;
}
.padding-right {
    padding-right: 0;
}
.padding-left {
    padding-left: 0;
}
.about-welcome-right img {
    height: 372px;
    width: 100%;
    object-fit: contain;
}
.about-welcome-left ul {
    padding-top: 20px;
}
.about-welcome-left h3 {
    color: #553A28;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin: 0;
}
.about-services-main {
	text-align: center;
}
.about-services-main p {
	font-size: 14px;
    color: #2C2C2C;
    line-height: 21px;
    max-width: 220px;
    margin: 0 auto;
}
.about-services-main h2 {
    padding-top: 20px;
}
.about-founder {
    padding: 35px 0;
}
.about-services {
    background: #FAFBFC;
    padding: 0px 0px 30px;
}
.required-fr {
    color: red !important;
    font-size: 16px !important;
}
.founder-description h5 {
    font-size: 16px;
    line-height: 21px;
    color: #553A28;
    font-weight: 700;
    margin: 0;
    padding-bottom: 5px;
}
.nametext {
    margin-right: 20px;
}
.founder-description p {
    font-size: 14px;
    color: #2C2C2C;
    line-height: 21px;
    margin: 0;
}
.founder-description {
    padding-bottom: 15px;
}
.about-founder-text {
	border-radius: 4px 0px 4px 4px;
	background-color: #fff;
	position: absolute;
	top: auto;
	bottom: -42px;
	right: auto;
	padding: 17px;
	left: 75px;
    width: fit-content;
    right: -30px !important;
}
.about-founder-text h3 {
    color: #553A28;
    font-size: 22px;
    line-height: 29px;
    text-transform: uppercase;
    margin: 0;
}
.about-founder-text p {
    margin: 0;
    font-size: 19px;
    line-height: 21px;
    color: #2C2C2C;
}
.about-founder-left {
    position: relative;
}
.map-locaion {
	text-align: center;
	padding: 65px 0px;
}
.map-locaion h2 {
	text-align: center;
    width: 100%;
    margin: 0;
    padding-bottom: 30px;
}
.our-kitchens-left p {
	font-size: 14px;
	line-height: 21px;
	color: #2C2C2C;
	margin-bottom: 2px;
}
.our-kitchens-left img {
    margin-top: 25px;
}
.award-slider {
    padding: 65px 0;
}
.award-slider h2 {
    width: 100%;
    text-align: center;
}
/*checkout css*/
.checkout-left {
    border-radius: 0px 0px 6px 6px;
    background-color: #fff;
    padding: 20px;
    min-height: 100%;
}
.checkout-left h2 {
    text-transform: capitalize;
    margin: 0;
    padding-bottom: 15px;
}
.delivery-address {
	border: 2px solid #F5F5F5;
	border-radius: 4px;
	padding: 15px;
	display: flex;
}
.delivery-address h4 {
	font-size: 16px;
    line-height: 20px;
    color: #553A28;
    font-weight: bold;
    margin: 0;
}
.delivery-address p {
	font-size: 14px;
	line-height: 18px;
	color: #2C2C2C;
	opacity: 67%;
	margin: 0;
	padding: 10px 0;
}
.delivery-address h5 {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    color: #2C2C2C;
}
button.btn.delivery-btn {
    border: 1px solid #553A28;
    border-radius: 4px;
    width: 100%;
    color: #553A28;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    font-weight: bold;
    margin-top: 10px;
    padding: 7px 15px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}
button.btn.delivery-btn:hover {
    background-color: #553A28;
    color: #fff;
}
button.btn.delivery-btn:focus {
    outline: none;
    box-shadow: inherit;
}
.delivery-text {
    padding-left: 25px;
}
.add-address {
	border: 2px solid #F5F5F5;
	border-radius: 4px;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}
.add-address p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.4px;
    color: #E5AB4E;
    margin: 0;
    font-weight: bold;
    padding-top: 20px;
}	
.checkout-left hr {
    border-color: #F5F5F5;
    margin: 30px 0;
}
.choose-payment h2 {
    margin: 0;
}
.choose-payment .nav-pills {
    background-color: #F0F1F2;
	border-radius: 4px;
}
.choose-payment .nav-pills .nav-link.active {
    color: #2C2C2C;
    background-color: #fff;
}
.choose-payment .nav-pills .nav-link {
	font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    color: #2C2C2C;
    padding: 10px 5px;
    border-radius: 4px;
    margin-left: 10px;
}
.tab-card {
	background-color: #004790;
	border-radius: 4px;
	padding: 13px 15px;
	max-width: 384px;
	justify-content: space-between;
	display: flex !important;
}
.tab-card span {
	color: #fff;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}
.add-new-card {
    border: 1px solid #F5F5F5;
    border-radius: 4px;
    max-width: 384px;
    margin-top: 30px;
    padding: 13px 15px;
    display: flex;
}
.add-new-card-text h5 {
    margin: 0;
    color: #2C2C2C;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
}
.add-new-card-text p {
    margin: 0;
    color: #2C2C2C;
    font-size: 12px;
    line-height: 15px;
    opacity: 67%;
}
span.plus-card-item {
    width: 37px;
    height: 22px;
    border: 1px solid #553A28;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
.add-new-card-text {
    padding: 0 20px;
}
.add-new-card-text ul li {
    list-style-type: none;
    display: inline-block;
}
.checkout-right {
    border-radius: 0px 0px 6px 6px;
    background-color: #fff;
    padding: 20px;
}
.quantity-text h4 {
    margin: 0;
    color: #553A28;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 10px;
}
.quantity-text p {
    color: #2C2C2C;
    font-size: 18px !important;
    line-height: 20px !important;
    margin: 0;
    font-weight: 700;
    padding-top: 0px !important;
}
.quantit-item {
    position: absolute;
    right: 0;
}
.quantit-item span {
    background-color: #FAFBFC;
    border-radius: 4px;
    width: 89px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #5CB346;
}
.quantity-text {
    padding-left: 20px;
}
.main-quantity {
    display: flex;
    position: relative;
}
.checkout-right p {
    margin: 0;
    padding-top: 10px;
}
.checkout-right hr {
    border-color: #F5F5F5;
}
.coupon-text p {
    color: #2C2C2C;
    font-size: 16px;
    margin: 0;
    line-height: 20px;
    font-weight: 700;
    padding: 0;
    padding-left: 5px;
}
.coupon-list {
    border: 2px solid #F5F5F5;
    display: flex;
    padding: 10px;
    position: relative;
    align-items: center;
}
.coupon-number span {
	border: 1px solid #D0973C;
    background-color: #E5AB4E;
    width: 95px;
    height: 24px;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0px;
    position: absolute;
    right: 12px;
    top: 16px;
}
.coupon-list.coupon-border {
    border-top: 0;
    border-bottom: 0;
}
.item-total {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}
.item-total p {
    padding: 0;
    font-size: 16px;
    color: #2C2C2C;
    line-height: 20px;
    opacity: 67%;
    font-weight: 500;
}
.main-item-total {
    padding-top: 20px;
}
.main-item-total .item-total:nth-child(2) {
    padding-bottom: 0px;
}
/*checkout css*/
/*terms-conditions*/
.terms-conditions-header {
    padding: 50px 0;
    border-bottom: 1px solid #F7F7F7;
    background-color: #fff;
}
.terms-conditions-header h2 {
    text-align: center;
    text-transform: capitalize;
}
.terms-conditions {
    background-color: #fff;
    padding: 50px;
    padding-bottom: 0;
}
.terms-conditions-content h4 {
    font-size: 20px;
    line-height: 21px;
    color: #3D404B;
    font-weight: bold;
    margin: 0;
    padding-bottom: 15px;
}
.terms-conditions-content p {
    color: #3D404B;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    margin: 0;
}
.terms-conditions-content {
    padding-bottom: 50px;
}
.terms-conditions-content ul li {
    color: #3D404B;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 20px;
    margin-left: 30px;
}
ul.terms-conditions-list li {
    padding-bottom: 5px;
}
/*terms-conditions*/

/*store locator*/
.store-locator-header {
    background-color: #553A28;
    padding: 25px 0;
}
.store-locator-header h2 {
    margin: 0;
    text-align: center;
    width: 100%;
    color: #fff;
    text-transform: capitalize;
    font-size: 25px;
    line-height: 30px;
    padding-bottom: 30px;
}
.store-locator-header .input-group {
    max-width: 382px;
    margin: 0 auto;
}
.store-locator-header .input-group input {
    height: 46px;
    border: 0;
    border-radius: 6px;
    color: #B2BAC3;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 19px;
}
.store-locator-header .input-group .input-group-append {
    background-color: #fff;
    border-radius: 0px 6px 6px 0;
}
.store-locator-header .input-group input:focus {
    outline: none;
    box-shadow: inherit;
}
.store-locator-header .input-group .input-group-append button {
    border: 0;
    border-radius: 0px 6px 6px 0;
}
.store-locator-header .input-group .input-group-append button:hover {
    background-color: #fff;
}
.store-locator-header .input-group .input-group-append button:focus {
    outline: none;
    box-shadow: inherit;
}
.store-locator-header .input-group .input-group-append button:active {
    box-shadow: inherit;
}
.store-locator-header .btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: inherit;
}
.store-locator-header p {
    text-align: center;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.4px;
    padding-top: 23px;
}
.store-locator-map {
    background-color: #FFFFFF;
}
.store-locator-header p img {
    margin-left: 3px;
    vertical-align: sub;
}
.store-text h4 {
    color: #553A28;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    padding-bottom: 5px;
    margin: 0;
}
.store-text p {
    color: #2C2C2C;
    opacity: 67%;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    max-width: 268px;
}
.store-km {
    position: absolute;
    right: 0;
}
.store-locator-map-text {
    display: flex;
    position: relative;
    padding-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #F7F7F7;
}
.store-locator-map-image img {
    height: 487px;
    object-fit: cover;
    width: 100%;
}
.store-text {
    padding-left: 25px;
}
.store-km span {
    font-size: 14px;
    line-height: 20px;
    color: #727272;
    font-weight: 500;
}
.store-locator-locations {
    padding: 50px 50px;
    border-bottom: 1px solid #F7F7F7;
    background-color: #fff;
}
.store-locator-locations-left h2 {
    color: #2C2C2C;
    text-transform: capitalize;
    font-size: 25px;
    line-height: 30px;
    margin: 0;
    font-weight: 500;
}
.store-locator-locations-right select {
    border: 1px solid #F0F1F2;
    border-radius: 6px;
    max-width: 316px;
    height: 46px;
    color: #2C2C2C;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    /* background: url('../../assets/polygon.svg') no-repeat 290px 22px; */
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    appearance: none;
}
.store-locator-locations-right select:focus {
    outline: none;
    box-shadow: inherit;
    border-color: #F0F1F2;
}
.store-locator-locations-address {
    padding: 50px 50px;
    background-color: #fff;
        margin: 25px;   
} 
.store-locator-locations-address-text h4 {
    font-size: 20px;
    line-height: 24px;
    color: #543a20;
    font-weight: 600;
    margin: 0;
    padding-bottom: 20px;
}  
.store-locator-locations-address-text h5 {
    color: #2C2C2C;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 15px;
}

.store-locator-locations-address-text h5 span {
    color: #553A28;
}
.store-locator-locations-address-text {
    padding-bottom: 50px;
}
/*store locator*/


/* My Account */
.myAccount {
    background: #FAFBFC;
    margin-top:23px;
    padding:0px !important;
    margin-bottom: 27px;

}
.accountSidebar {
    margin-top:0px;
    margin-left:25px;
}
.accountSidebar ul {
    list-style: none;
}
.accountSidebar ul li {
    padding: 12px;
}
.accountSidebar ul li.active {
    background: rgb(85 58 40 / .15);
    border-radius: 4px;
}
.accountSidebar ul li.active a {
    color: #553A28;
    font-weight: 600;
}
.accountSidebar ul li a {
text-align: left;
font: normal normal normal 16px/34px Lato;
letter-spacing: 0px;
color: #748090;
opacity: 1;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.accountSidebar ul li img {
width: 24px;
height: 24px;
background: transparent 0% 0% no-repeat padding-box;
opacity: 1;
    margin-right: 6px;
}
.whiteBox {
    background: #fff;
    border-radius: 6px;
    padding: 30px;
    padding-bottom:0px !important;
    box-shadow: 0px 0px 20px #0000000D;
    font-size: 14px;
}
.myAccHeading {
    height: 24px;
    text-align: left;
    font: normal normal bold 20px/34px Lato;
    letter-spacing: 3px;
    color: #E5AB4E;
    text-transform: uppercase;
    opacity: 1;
    letter-spacing: 3px;
}
.whiteBox p {
text-align: left;
font: normal normal normal 14px/20px Lato;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
}
.whiteBox {
    padding-bottom: 61px !important;
}
.inlabel3 {
    text-align: left;
font: normal normal normal 14px/34px Lato;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
margin-bottom: 20px;
}
.myAccSubHeading {
   margin-top: 26px;
text-align: left;
font: normal normal bold 14px/34px Lato;
letter-spacing: 1.4px;
color: #553A28;
text-transform: uppercase;
opacity: 1;
    border-bottom: 1px solid #F4F4F4;
    padding-bottom: 20px;
}
.inputGroup {
    position: relative;
}
.inlabel {
text-align: left;
font: normal normal normal 14px/34px Lato;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
}
.inputGroup input,
.inputGroup select {
    border: 1px solid #E6E6E6;
    background: #FCFCFC;
    width: 100%;
    height: 44px;
    padding: 10px;
    border-radius: 4px;
    font-weight: 600;
}
.inputGroup input::placeholder,
.inputGroup select,
.inputGroup input[type="date"] {
    color: #BCBEC0;
}
.inputGroup button {
    position: absolute;
    right: 10px;
    top: 10px;
    background: no-repeat;
    border: none;
    color: #553A28;
    font-weight: 600;
}
.inputGroup button img {
    width: 20px;
}

/* My Account */


@media only screen and (max-width: 1024px) {
	.about-founder-text {
	    padding: 12px;
	}
    .myAccount {
        padding-right: 20px !important;
    }
}
@media only screen and (max-width: 1150px) {
    .myAccount {
        padding-right: 20px !important;
    }

}
@media only screen and (max-width: 991px) {
	.about-founder-text {
	    position: sticky;
	    margin: 15px 0;
	}
    .fillnow {

    }
    .store-locator-locations-address {
        padding: 25px 0;
    }   
    .myAccount {
        padding-right: 20px !important;
    }
}
@media only screen and (max-width: 767px) {
	.padding-left {
	    padding-left: 15px;
	}
    .myAccount {
        padding: 0px !important;
    }
    .myAccount-fr {
        padding: 0px !important;
    }
    .accountSidebar {
        margin-right: 25px !important;
    }
	.padding-right {
	    padding-right: 15px;
	}
	.about-welcome {
	    padding: 25px 0 40px;
	}
	.about-services .col-lg-4.col-md-4:nth-child(2) .about-services-main {
	    padding: 25px 0;
	}
	.our-kitchens-right {
	    padding-top: 30px;
	}
    .terms-conditions {
        padding: 15px 0;
    }
    .store-locator-locations-address {
        padding: 25px 0;
    }
    .mmt {
        margin-top: 1rem;
    }
    .savebtn {
        float: left !important;
    }
}

.myAccSubHeading {
    /* border-bottom: 1px solid #F4F4F4; */
    font-size: 14px;
    color: #553A28;
    text-transform: uppercase;
    margin: 30px 0 20px;
    /* padding-bottom: 20px; */
}


.myAccSubHeading-bonus-alert {
    /* border-bottom: 1px solid #F4F4F4; */
    color: #553A28;
    text-transform: uppercase;
     font-weight: bold;
	font-family: 'Lato', sans-serif;
letter-spacing: 1.4px;
    /* padding-bottom: 20px; */
}

@media only screen and (max-width: 600px) {

    .fillnow {
        width: 100px !important;
        margin-left: 10px !important;
    }


}

