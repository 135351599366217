@import "~bootstrap/scss/bootstrap";

body {
  background-color: #fff;
}

.view-cart-sticky-drawer {
    position: fixed;
    bottom: 0px;
    left: 0; right: 0;
    transform: none;
    border-radius: 0px;
    box-shadow: 1px 1px 3em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
    z-index: 1204;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.vc-cart-count-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vc-cart-items-count {
    border-radius: 50% !important;
    position: absolute;
    right: -10px;
    top: -5px;
    transform: scale(0.8);
    background-color: #3c1004 !important;
    color: #fff !important;
}

.cart-btn{
  border: none;
    border-radius: 6px;
    padding: 5px 7px;
    background: none;
}

.btn-primary {
  color: #fff;
  background-color: #E5AB4E;
  border-color: #E5AB4E;
}

@media (min-width: 768px) {
    .view-cart-sticky-drawer {
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 360px;
        border-radius: 6px;
        box-shadow: 1px 1px 3em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
        z-index: 1204;
        right: unset !important;
    }
}