.modal-backdrop {
    background-color: #2C2C2C;
  }
  .login {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  }
  .modal-backdrop.show {
    opacity: .8;
  }
  .modal-open .modal {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    /* -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px); */
  }
  .modal.show .modal-dialog {
    display: flex;
    align-items: center;
    max-width: 350px;
  }
  .modal-header {
    border-bottom: none !important;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 !important;
  }
  .modal-header .close {
    padding: 0 !important;
    margin: 0 !important;
    width: 30px;
    height: 30px;
    background: #f4f4f4;
    border-radius: 50%;
    color: #d4d4d4;
    opacity: 1;
    z-index: 9;
  }
  .modal-body {
    padding: 26px !important;
    text-align: center;
  }
  .modal-body .modalHeading {
    color: #553A28;
    font-size: 30px;
    font-weight: 600;
  }
  .modal-body .pera {
    color: #292D36;
    font-size: 14px;
    margin: 12px 0 25px;
    text-align: center;
  }
  .modal-body .emailInput {
    width: 100%;
    background: #F0F1F2;
    border: none;
    height: 45px;
    border-radius: 6px;
    padding: 15px;
  }
  .modalLoginBtn {
    background: linear-gradient(to right, #3B291D, #553A28);
    border: none;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }
  
  .modalDangerBtn {
    background: #dc3545!important;
    border: none;
    width: 89%;
    height: 50px;
    border-radius: 6px;
    margin: 0px auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }
  
  .modalSecondaryBtn {
    background: transparent;
    border: none;
    margin-top: 16px;
    color: #939393;
    font-size: 20px;
    font-weight: 600;
  }
  .haveAcc {
    margin-top: 25px;
    color: #748090;
    font-size: 14px;
  }
  .haveAcc a {
    color: #553A28;
    font-weight: 600;
  }
  .divider {
    display: flex;
    align-items: center;
    margin: 25px 0 15px;
  }
  .dividerBorder {
    width: 100%;
    height: 1px;
    background: #F4F4F4;
  }
  .dividerText {
    color: #748090;
    font-size: 12px;
    margin: 0 15px;
  }
  .modalSocial img {
    margin: 0 10px;
  }
  .contactNumber {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contactNumber p {
    font-size: 16px;
    font-weight: 600;
    margin: 0 10px;
    color: #A77D61;
  }
  .contactNumber button {
    background: #A77D61;
    color: #fff;
    border: solid 1px #9B7257;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 4px;
    padding: 3px 9px;
  }
  
  .otp-flow {
    /* margin-top: 65px; */
    display: flex;
    justify-content: center;
  }
  .otp-flow input {
    width: 50px;
    height: 50px;
    background: #F0F1F2;
    border-radius: 6px;
    font-size: 28px;
    text-align: center;
    border: none;
    margin: 0 13px;
  }
  .otp-flow input:focus {
    background: #fff;
    border: solid 2px #F0F1F2;
    outline: none;
  }
  .otp-flow input::-webkit-outer-spin-button,
  .otp-flow input::-webkit-inner-spin-button {
    /* -webkit-appearance: none; */
  }
  .resend {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #49CE8B;
    font-size: 16px;
    font-weight: 600;
    margin: 25px 0 0px;
  }
  .resend img {
    margin-left: 7px;
  }
  input.emailInput.flagInput {
    padding-left: 65px;
  }
  
  .custom-input {
    padding: 10px;
    min-height: 50px;
    width: 86%;
    height: 100%;
    border: 1px solid #e2e2e2;
    border-radius: 5%;
    margin: 10px 0px;
  }
  
  .custom-input:focus {
    background: #fff;
    border: solid 2px #F0F1F2;
    outline: none;
  }
  
  .countryFlag {
    /* position: absolute; */
     
    /* left: 12px; */
    /* top: 3px; */
    /* background-color: #F0F1F2 */
  }
  .countryFlag input {
    padding: 10px;
    min-height: 50px;
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 6px;
    background-color: #F0F1F2;
  }
  .countryFlag input:focus {
    outline: none;
  }
  
  .countryFlag .PhoneInputCountryIcon {
    border-radius: 50%;
    overflow: hidden;
    width: 45px;
    height: 45px;
    border: none;
    box-shadow: none;
  }
  .createnowbtn {
    cursor: pointer;
    text-decoration: none;
  }
  .createnowbtn:hover {
    cursor: pointer;
    text-decoration: none;
    color:#553A28;
  }
  .fb {
    margin-bottom: 10px;
  }
  @media (min-width: 576px)
  {
    .modal-dialog {
      max-width: 400px;
    }
  }
  @media screen and (max-width: 600px) {
    .modal.show .modal-dialog {
      display: flex;
      align-items: center;
      max-width: 350px;
      margin-left:10px !important;
      margin-right: 10px !important;
    }
  }