.contactUs {
    background: #fff;
    margin-top:103px;
    margin-left:20px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 36px;
    margin-right: 20px !important;
}
.noPadding {
    padding: 0;
}
.required-fr {
    color: red !important;
    font-size: 16px !important;
}
 input[required=required]:after,
select[required=required]:after,
textarea[required=required]:after {
    color: red !important;
    content: " *";
}
.storedefaultname {
    background-color: #553A28 !important;
    color:#fff;
    margin-bottom: 10px !important;
}
/* .select.form-control:hover {
    background-color: #553A28 !important;
    color:#fff;
} */
.prbtn {
    padding-top: 14px;
    padding-bottom: 14px;
    margin-bottom:84px;
    padding-left:100px;
    padding-right:100px;
    background: linear-gradient(to right, #3B291D, #553A28);
border-radius: 4px;
color:#FFFFFF;
opacity: 1;
outline: none;
border: 0;
}
.contactDetails {
    background: linear-gradient(to right, #3B291D, #553A28);
    color: #fff;
    text-align: center;
    /* padding-top:85px; */
    padding-top:15px;
    text-align: center;
    padding-left:15px;
    padding-right:15px;
    padding-bottom: 15px;
    border-radius: 6px 0 0 6px;
    height: 100%;
}
.contactForm {
    background: #fff;
    /* padding-top:84px; */
    padding-top:15px;
    padding-left:49px;
}
.textarea-fr {
    min-height: fit-content;
}
.formSection.contactForm textarea.form-control {
    min-height: 51px;
}
.contactBox a {
    height: 24px;
    margin-top: 8px !important;
    opacity: 1 !important;
    font-size: 17px !important;
    font-weight: normal !important;
    color:#FFFFFF !important;
    font-family: 'Lato' !important;
}
.writeto {
    font: normal normal medium 25px/30px Lato !important;
    letter-spacing: 0px;
    font-size: 25px !important;
    line-height: 30px !important;
    font-family: Lato !important;
    color: #553A28;
    text-transform: none !important;
    opacity: 1;

}
.form-control {
    margin-right:60px;
}
.contactBox {
    /* margin-top: 50px; */
    margin-top: 36px;
}
.contactBox small {
    padding-top:8px;
    opacity: 1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 100;
    height:15px;
    letter-spacing: 1.5px !important;

}
.formSection {
    margin-left:0px !important;
    margin-right: 21px !important;
}
.contactBox p {text-align: center;
height: 24px;
    margin-top: 8px !important;
    opacity: 1 !important;
    font-size: 17px !important;
    font-weight: normal !important;
    color:#FFFFFF !important;
    font-family: 'Lato' !important;
}
.phn {
    margin-top:8px;
    margin-bottom:8px;
}
@media only screen and (max-width: 767px) {
    .contactForm {
        padding: 15px;
    }
    .contactDetails {
        border-radius: 6px;
    }
    .writeto {
        text-align: center !important;
    }
    .formSection p {
        text-align: center !important;
    }
    .formSection {
        margin-right: 0px !important;
    }
    /* .form-control {
        width: inherit !important;
    } */
    .prbtn {
        width: inherit !important;
    }

}
@media only screen and (max-width: 600px) {
    /* .prbtn {
        width: 450px !important;
    } */
}


.phn a {
    color: #fff;
}
