@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

body {
	font-family: 'Lato', sans-serif;
	background-color: #FAFBFC;
}
ul {
	margin: 0;
	padding: 0;
}
h2 {
  
    left: 20px;
    text-align: left;
    font: normal normal bold 18px/18px Lato !important;
    letter-spacing: 2px;
    color: #553A28;
    text-transform: uppercase;
    opacity: 1;
}
.about-welcome-left p {
    color: #2C2C2C;
    font-size: 18px ;
    line-height: 24px !important;
    max-width: 513px;
    padding-bottom: 34px;
    font-family: 'Lato';
    font-weight: normal !important;
    margin: 0;
    text-align:justify !important;
}
.about-welcome {
    margin-top: 103px;
    background-color: white;
    padding-top:0px !important;
    /* margin-left: 20px;
    margin-right: 20px; */
    
   
}
.about-welcome-fr {
    margin-top: 51px;
    background-color: white;
    padding-top:0px !important;
    padding-left: 10px;
    padding-right: 10px;
}
.content {
    margin:0px !important;
}

.about-welcome-left ul li {
    list-style-type: none;
    display: inline-block;
    color: #553A28;
    font-size: 18px;
    line-height: 22px;
    padding: 0 0px 0px 15px;
    text-align: center;
}
.about-welcome-left ul li:first-child {
    padding-left: 0;
}
.about-welcome-left ul li img {
    max-width: 30px;
    margin: 0 auto;
    margin-bottom: 10px;
    transition: transform .2s;
  
}
.about-welcome-left ul li img:hover {
    -ms-transform: scale(1.5); 
  -webkit-transform: scale(1.5);
  transform: scale(1.5); 
}
.mt-5-fr {
    margin-top:0px !important;
}
.about-welcome-left {
    background-color: #fff;
    padding-top: 28px !important;
    padding-left: 5px !important;
    padding-bottom: 28px !important;
    animation: fadeIn ease 3s;

}
.textabout {
    font: normal normal bold 20px/24px Lato;
letter-spacing: 2px;
color: #553A28;
text-transform: uppercase;
font-weight: bold !important;
font-family: Lato !important;
font-size: 20px !important;
opacity: 1;
}
@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }
 
 @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }
 
 .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
 }
.padding-right {
    padding-right: 0;
    
}
.padding-left {
    padding-left: 0;
    padding-right: 0px !important;
}
.MuiRating-decimal {
    color: #E5AB4E !important;
}
.slick-arrow {
    width: 45px !important;
    height: 45px !important;
    margin-left: -25px !important;
    margin-right: -15px !important;
}
.about-welcome-right-img {
    height: 100% !important;
    width: 100%;
    object-fit: contain;
    position: absolute;
    margin-top: -22px !important;
    border-radius: 6px !important;
        animation: 1s slide-left;
}
.ordermodes-fr {
    background-image:url('../../../images/bannerdelivery.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border: none;
}
.aboutusname {
    font: normal normal bold 20px/24px Lato !important;
    letter-spacing: 2px !important;
    line-height: 24px !important;
    color: #553A28;
    text-transform: uppercase;
    opacity: 1;
    text-align: left;
    font-weight: bold !important;
}
.slick-prev:before {
    color: #553A28 !important;
}
.aboutustext {
    text-align: left;
    font: normal normal medium 18px/24px Lato;
    font-family: 'Lato' !important;
    line-height: 24px!important;
    font-weight:400 !important;
    letter-spacing: 0px;
    color: #2C2C2C;
    opacity: 1;
}

/* .neworder-fr {
    margin-top: 50px !important;
    position: relative;
    width: 327px !important;
    height: 348px !important;
    margin-right: 30px !important;
} */
.neworder-fr {
    margin-top: 50px !important;
    position: relative;
    width: 347px !important;
    height: 348px !important;
    margin-right: 30px !important;
    margin-left: 32px !important;
}
.orderheader {
text-align: left;
color: #FFFFFF;
letter-spacing: 0px;
font: normal normal normal 35px/42px Lato;
text-transform: capitalize;
opacity: 1;
/* padding-top:20x; */
padding-right: 0px !important;
}
.ordertext {
    font-weight: lighter !important;
text-align: left;
font: normal normal normal 14px/16px Lato;
letter-spacing: 0px;
color: #FFFFFF !important;
text-transform: capitalize;
opacity: 1;
line-height: 21px !important;
padding-right: 43px;
  
}
.MuiBox-root{
    margin-top: 70px !important;
    margin-bottom: 0px !important;
}

.feedbackimges {
 text-align: center;
 margin-left: -30px !important;

}
.card-header-title {
    font-size: 20px !important;
    margin-top:20px !important;
}
.ordertotal-fr {
    padding-left:0px !important;
    padding-top:0px !important;
}
.ordertotal-fr h2 {
    font-size:16px;
}
.search {
    background-image: url('../../../images/Group\ 35380.svg');
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    padding:15px;
    border: none;
    background-color: #F0F1F2;
    border-radius: 6px;
}
.search:focus {
    outline: none;
    border: none;
}
.veg {
    text-align: center;
font: normal normal 600 14px/17px Lato;
letter-spacing: 2.1px;
color: #2C2C2C;
text-transform: uppercase;
opacity: 1;
margin-right: 20px;
}
.custom-control-input {
    background: #18A56D 0% 0% no-repeat padding-box;
border-radius: 20px;
opacity: 1;
}
.founder-text {
    font-size: 14px !important;
    font-family: Lato;
    font-weight: normal;
    color: #2C2C2C !important
}
.orderflex {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 41px;
    padding: 20px !important;
}
.feedback-fr {
    margin-left: 20px;
    margin-right: 20px;
}
.ordertitle {
    margin-top:10px;
    padding-top:2px;
    text-align: left;
font: normal normal 600 20px/24px Lato;
letter-spacing: 0px;
color: #FFFFFF;
text-transform: capitalize;
opacity: 1;
}
.customername{
    text-align: center;
font: normal normal bold 18px/26px Lato;
letter-spacing: 0px;
color: #262626;
text-transform: capitalize;
opacity: 1;
}
.customermsg {
    text-align: center;
/* font: normal normal normal 15px/24px Lato; */
font: normal normal normal 13px/24px Lato;
letter-spacing: 0px;
color: #231F20;
/* text-transform: capitalize; */
font-weight: normal !important;
opacity: 1;
padding: 0px 20px;
}
.slick-dots {
    bottom: -16px !important;
}
.MuiCardContent-root {
    padding-top:0px !important;
}
.MuiBox-root-3 {
    text-align: center;
}
.MuiBox-root-4 {
    text-align: center;
}
.MuiBox-root-5 {
    text-align: center;
}
.orderptag {
    text-align: left;
font: normal normal normal 17px/21px Lato !important;
letter-spacing: 0px !important;
color: #FFFFFF !important;
opacity: 1 !important;
padding-top:5px;
margin-bottom: 26px !important;
font-weight: lighter !important;
}
.suborderheader {
    text-align: left;
padding-top:20x;
font: normal normal 600 35px/42px Lato;
letter-spacing: 0px;
color: #E5AB4E;
text-transform: capitalize;
}
.about-services-fr {
    border-radius: 6px !important;
}
@keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }

.about-welcome-left ul {
    padding-top: 20px;
}
.awardimages {
    /* padding-right:15px; */
    object-fit: contain;
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.awardrow {
    display: flex;
    width: -webkit-fill-available;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    scroll-behavior: smooth;
    /* margin-top:51px; */

}
.awardrow::-webkit-scrollbar{
    display: none;
}

.ab-card{
    flex:0 0 auto;

}

.award-slider .col-md-1 {
    margin-right:30px;
}
.about-welcome-left h3 {
    left: 20px;
    text-align: left;
    font: normal normal bold 18px/18px Lato !important;
    letter-spacing: 2px;
    color: #553A28;
    text-transform: uppercase;
    opacity: 1;
}
.about-services-main {
	text-align: center;
}
.about-services-main img {
    transition: transform .2s;
}
.about-services-main img:hover {
     -ms-transform: scale(1.5); 
  -webkit-transform: scale(1.5);
  transform: scale(1.5); 
}
.about-services-main p {
    font: normal normal normal 14px/21px Lato !important;
    height: 38px !important;
    letter-spacing: 0px !important;
    color: #2C2C2C !important;
    opacity: 1 !important;
    padding-top: 9px !important;
    max-width: 220px;
    margin: 0 auto;
}
.about-founder-right  {
    margin-top:59px;
    margin-left: 20px;
}
.about-founder-right p {
/* text-align: left;
letter-spacing: 0px;
opacity: 1;
font-size: 16px !important;
font-family: 'Lato' !important;
line-height: initial;
font-weight: 500 !important; */
text-align: justify;
letter-spacing: 0px;
opacity: 1;
font-size: 18px !important;
/* font-family: 'Lato' !important; */
line-height: normal;
font-weight: normal;

}
.about-services-main h2 {
    padding-top: 20px;
    font: normal normal bold 14px/24px Lato !important;
    height: 24px !important;
    font-family: 'Lato' !important;
    letter-spacing: 2px !important;
    color: #553A28;
    text-transform: uppercase;
    opacity: 1;
}
.about-founder {
    padding: 35px 29px !important;
    padding-bottom: 83px!important;
    background-color: white;
}
.about-services {
    background: #FAFBFC;
    padding: 0px 0px 30px;
}
.refer-section-header {
    letter-spacing: 2px !important;
    font: normal normal bold 20px/24px Lato !important;
color: #553A28;
text-transform: uppercase;
opacity: 1;
padding-top: 43px !important;
}
.refer-section-fr-img {
    margin-top: 31px !important;
}
.refer-section-text  {
    text-align: left !important;
letter-spacing: 0px !important;
color: #2C2C2C !important;
opacity: 1;
font-size: 16px !important;
    font-weight: normal;
    font-family: 'Lato';
    height: 143px;
    width: 372px;

}
.refer-section-fr-grey {
    background: #FAFBFC 0% 0% no-repeat padding-box !important;
border-radius: 8px !important;
opacity: 1;
display: flex;
flex-direction: row;
width: fit-content;
}
.refer-grey-one {
    display: flex;
    flex-direction: row;
    margin-right: 152px !important;
    padding:19px;
}

.refer-grey-second {
    display: flex;
    flex-direction: row;
    padding:19px;
}
.refer-section-fr-one {
    width: 84px !important;
    height: 38px !important;
    font: normal normal 600 16px/19px Lato;
    font-weight: 600 !important;
    font-size: 13px !important;
    font-family: Lato !important;
    letter-spacing: 0px !important;
    color: #4D3A21 !important;
    opacity: 1;
    
}
.refer-section-fr-one::after {
    content: "|";
    font-size: 17px;
    padding: 0px 99px;
    position: absolute;
    color: #2C2C2C;
    opacity: 0.2;
}

.refer-section-fr-second {
    width: 114px !important;
    height: 38px !important;
    font: normal normal 600 16px/19px Lato;
    font-weight: 600 !important;
    font-size: 13px !important;
    font-family: Lato !important;
    letter-spacing: 0px !important;
    color: #4D3A21 !important;
    opacity: 1;
    
}
.refer-section-fr-btn {
    background-color: #E5AB4E !important;
    color: #2C2C2C !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    padding-left: 13px !important;
    margin-top: 47px !important;
    border-radius: 6px !important;
border: none !important;
font: normal normal bold 20px/24px Lato;
font-size: 20px !important;
font-weight: bold !important;
line-height: 24px !important;
letter-spacing: 0px;
color: #2C2C2C !important;
text-transform: none !important;
opacity: 1;
text-align: start;
margin-bottom: 43px !important;
}
.referarrow {
    float: right !important;
    /* margin-left: 172px !important; */
}
.founder-description h5 {
    font-size: 16px;
    line-height: 21px;
    color: #553A28;
    font-weight: 700;
    margin: 0;
    padding-bottom: 5px;
}
.founder-description p {
    font-size: 14px;
    color: #2C2C2C;
    line-height: 21px;
    margin: 0;
    font-weight: normal !important;
}
.founder-description {
    padding-bottom: 15px;
    color:#000;
}
.content-fr {
    font-size: 16px !important;
}
.about-founder-text {
	border-radius: 4px 0px 4px 4px;
	background-color: #fff;
	position: absolute;
	top: auto;
	bottom: -42px;
	right: -30px !important;
	padding: 17px;
    width: fit-content;
	/* left: 75px; */
    left: auto !important;
    box-shadow: #dce3eb86 9px 11px 8px !important;
}
.about-founder-text h3 {
text-align: left;
/* font: normal normal 600 10px Lato; */
font: normal normal 600 16px Lato;
letter-spacing: 0px;
/* font-size: 12px !important; */
color: #553A28;
    margin: 0;
}
.about-founder-text p {
    margin: 0;
    font-size: 19px;
    color: #2C2C2C;
    padding-top:5px;
    font-size: 12px !important;

}
.about-founder-left {
    position: relative;
}
.about-founder-left img {
    animation: 10s slide-right;
    /* height:378px !important;
    width: 378px !important; */
}
.map-locaion {
	text-align: center;
	padding: 51px 0px;
}
p {
    text-align: start;
}
.map-locaion h2 {
	text-align: center;
    width: 100%;
    margin: 0;
    padding-bottom: 30px;
}
.our-kitchens{
    background-color: white;
  
}
.our-food {
    margin-left: 20px;
    margin-right: 20px;
}
.appnewtext {
    text-align: start;
}
.our-kitchens-left h2 {
    margin-top:51px;

}

/* .ordersubrow {
    margin-left: 0px !important;
} */
.our-kitchens-left p {
font-size: 18px !important;
text-align: justify;
font: normal normal normal 14px/21px Lato;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
	margin-bottom: 2px;
    font-weight: normal !important;
    line-height: inherit  !important ;
}
.about-welcome-right {
    height: 100% !important;
}

/* .our-kitchens-left img {
    margin-top: 45px !important;
    margin-bottom:38px;
    animation: 10s slide-top;
} */
.our-kitchens-left img {
    margin-bottom: 0px !important;
    animation: 10s slide-top;
    margin-top: 25px;
    padding-right: 20px !important;
    position: absolute;
    bottom: 51px;
}
@keyframes slide-top {
    from {
      margin-top: 100%;
    }
    to {
      margin-top: 0%;
    }
  }
.our-kitchens-right {
    margin-top:51px;
    margin-bottom: 51px;
}
.our-kitchens-right img {
    animation: 10s slide-left;
}
@keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }
.award-slider {
    padding: 65px 0;
}
.award-slider h2 {
    width: 100%;
    text-align: center;
    -webkit-animation:spin 4s linear;
    -moz-animation:spin 4s linear;
    animation: 4s linear fadeIn;

}
.awardrow img {
    animation: 2s slide-left ;
}

 .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
 }
@media only screen and (max-width: 1024px) {
	.about-founder-text {
	    padding: 15px;
	}
}
@media only screen and (max-width: 767px) {
	.padding-left {
	    padding-left: 15px;
	}
	.padding-right {
	    padding-right: 15px;
	}
	.about-welcome {
	    padding: 25px 0 41px;
        padding-bottom: 0px !important;
	}
	.about-services .col-lg-4.col-md-4:nth-child(2) .about-services-main {
	    padding: 25px 0;
	}
	.our-kitchens-right {
	    padding-top: 30px;
	}
    .ordermodebox {
        margin-top: 30px !important;
    }
    .about-founder-left {
        text-align: center;
    }
    .about-founder-text {
     width: 200px;
     margin: auto !important;   
     text-align: start;
    }
    .our-kitchens-right {
        padding-top: 0px;
        margin-top: 0px;
    }
}
@media only screen and (max-width: 768px) {
    .ordermodebox {
        margin-top: 0px !important;
    }   
    .ordertakeaway-fr {
        margin-top: 0px !important;
    }
    .about-welcome-left {
        background-color: #fff;
        padding: 30px 41px;
    }
    .about-founder-right {
        margin-top: 0px;
    }
}
@media only screen and (max-width: 991px) {
.about-founder-text {
    position: absolute !important;
}
/* .ordertakeaway-fr {
    margin-top: 0px !important;
} */

}
@media only screen and (max-width: 600px) {
    .our-kitchens-left img {
        margin-top: 1px !important;
        margin-bottom: 20px !important;
        padding-right: 0px !important;
        position:relative;
    }
    .img-location-new {
        width: 300px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    .orderheader {
        text-align: center;
        color: #FFFFFF;
        letter-spacing: 0px;
        font: normal normal normal 24px/42px Lato;
        text-transform: capitalize;
        opacity: 1;
        padding-right: 0px !important;
        }
        .feedback-fr {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .our-kitchens {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .our-food {
            margin-left: 10px;
            margin-right: 10px;
        }
        .our-kitchens-left h2 {
            text-align: center !important;
        }
        .our-kitchens-left p {
            text-align: center !important;
        }
        .about-fr {
            margin-top: 51px !important;
        }
        .customername {
            font: normal normal bold 18px/16px Lato !important;
        }
        .customermsg {
            /* font: normal normal normal 15px/24px Lato !important; */
            font: normal normal normal 13px/24px Lato;
            margin-bottom: 0px !important;
            width: 300px;
        }
        .about-founder-right {
            margin-top: 61px;
        }
        .about-fr {
            text-align: center;
        }
        .founder-text {
            text-align: center !important;
        }
        .founder-description {
            text-align: center !important;
        }
        .founder-description-fr {
            text-align: center !important;
        }
        .suborderheader {
            text-align: center;
        font: normal normal 600 24px/42px Lato;
        letter-spacing: 0px;
        color: #E5AB4E;
        text-transform: capitalize;
        }
        .about-welcome-left p {
            text-align: center !important;
            font-size: 18px !important;
        }
        .about-welcome-left h2 {
            text-align: center;
            font-size: 18px !important;
        }
        .appnewfooter {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .d-flex-fr {
            width: 100px;
            margin-left: 102px !important;
            margin-right: auto;
        }
        /* .globalimgone {
            width: 382px !important;
            height: 392px !important;
        } */
        .orderflex {
            padding-bottom: 20px !important;
        }
        .appnewh2 {
            text-align: center;
            font: normal normal normal 35px/42px Lato !important;
            letter-spacing: 0px;
            font-weight: normal !important;
            color: #2C2C2C;
            font-family: 'Lato' !important;
            margin-top: 35px !important;
        }
        .appnewtext {
            letter-spacing: 0px;
            color: #2C2C2C;
            font-weight: bold !important;
            /* font-size: 17px !important; */
            font-size: 35px !important;
            opacity: 1;
            line-height: 21px !important;
            font-family: 'Lato' !important;
            margin-bottom: 12px !important;
            margin-top: 30px !important;
            text-align: center;
        }
        .slick-dots {
            /* bottom: 10px !important; */
            bottom: -13px !important;
        }
        .appnewbody {
            margin-bottom: 35px !important;
        }
    
        .appnewlinks {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 12px !important;
            align-items: baseline;
            margin-top: 10px;
        }
        .appnewseconddiv{
            margin-left: 0px !important;
        }
        .aboutustext {
            text-align: center;
            font: normal normal medium 18px/24px Lato;
            font-family: 'Lato' !important;
            line-height: 24px!important;
            font-weight:400 !important;
            letter-spacing: 0px;
            color: #2C2C2C;
            opacity: 1;
        }
        .about-welcome-left h3 {
            text-align: center;
            font: normal normal bold 14px/17px Lato !important;
            letter-spacing: 1.4px !important;
            font-weight: bold !important;
            text-transform: uppercase;
            opacity: 1;
        }
        .about-welcome-left ul {
            text-align: center;
        }
        .about-welcome-left ul li{
            font: normal normal medium 18px/22px Lato;
            letter-spacing: 0px;
            color: #553A28;
            text-transform: capitalize;
            opacity: 1;
        }
        .feedbacknew {
            margin-top: 0px !important;
        }
        .about-services-main {
            margin-bottom: 49px !important;
        }
        .MuiRating-root {
            font-size: 26px !important;
            color: #E5AB4E !important;
        }
        .MuiBox-root {
            margin-top:70px !important;
            margin-bottom: 0px !important;
        }
        .aboutusname {
            text-align: center;
            font: normal normal bold 14px/17px Lato !important;
            letter-spacing: 1.4px !important;
            font-weight: bold !important;
            text-transform: uppercase;
            opacity: 1;
        }
        .orderptag {
            text-align: center;
            padding-left: 0px !important;
        }
        .about-founder-left img  {
            height: auto !important;
            width: auto !important;
        }
        .ourstoryp {
            font: normal normal normal 14px/21px Lato !important;
        }
        .about-founder {
            /* padding: 35px 13px !important; */
            padding: 35px 13px !important;
        }
        .about-welcome-right img {
            width: 100%;
            object-fit: cover;
                animation: 1s slide-left;
                height:200px;
        }
        .ordermodes-fr {
            background-image:url('../../../images/bannerdelivery.jpeg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
        .about-founder-right{
            /* padding-left: 15px !important;
            padding-right: 15px !important; */
            margin-right: 0px;
            margin-left: 0px !important;
        }
        .about-founder-left {
            text-align: center !important;
        }
        .about-founder-text-p {
            text-align: center !important;
            font-size: 14px !important;
            color: #553a20 !important;
        }
        .neworder-fr {
            margin-left: 60px !important;
        }
        
        .about-founder-text {
            right: 30px !important;
            position:absolute;
            border-radius: 4px 0px 4px 4px;
            background-color: #fff;
            position: absolute;
            top: auto;
            bottom: -42px;
            margin-left: auto;
            width: 250px !important;
            padding: 17px;
            left: 75px;
            box-shadow: #dce3eb86 9px 11px 8px !important;
        }
        .about-founder-text h3 {
            text-align: center !important;
            font-size: 12px !important;
            color: #553a20 !important;
        }
        .our-kitchens-right {
            padding-top: 0px !important;
            margin-top: 0px !important;
        }
        .storyImg {
            margin-top: 0px !important;
        }
        /* .ordertakeaway-fr {
            margin-top: 30px !important;
        } */
        .textabout {
            letter-spacing: 0.6px !important;
        }
        

}
@media only screen and (max-width: 575px) {

    
  
    .about-founder-right p {
        text-align: center;
    }
    .img-location-new {
        width: 300px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}
@media only screen and (max-width: 450px) {

    /* .neworder-fr {
        margin-left: 47px !important;
        width: 300px !important;
    } */
    .ordertext {
        padding-right: 30px;
    }
    /* .ordertakeaway-fr {
        margin-top: 30px !important;
    } */
    .img-location-new {
        width: 300px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}
@media only screen and (max-width: 414px) {
    .neworder-fr {
        margin-left: 47px !important;
    }
    .d-flex-fr {
        width: 100px;
        margin-left: 83px !important;
        margin-right: auto;
    }
    .ordersubrow {
        margin-left: 0px !important;
        margin-right: 0px !important;
     }
     .ordermodebox {
         margin-top: 0px !important;
     }
     /* .ordertakeaway-fr {
         margin-top: 30px !important;
     } */
     .ordermain {
        padding: 0px !important;
        margin-right: 10px !important;
     }
     .takeawayimgmobile{
         margin-top: -47px !important;
     }
     .refer-section-header {
         text-align: center !important;
     }
     .img-location-new {
        width: 300px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}
@media only screen and (max-width: 450px) {
    .neworder-fr {
       width: 300px !important;
    }
    .ordersubrow {
        margin-left: 0px !important;
        margin-right: 0px !important;
     }
     .ordermodebox {
         margin-top: 0px !important;
     }
     /* .ordertakeaway-fr {
         margin-top: 30px !important;
     } */
     .refer-section-header {
        text-align: center !important;
    }
     .ordermain {
        padding: 0px !important;
        /* margin-right: 10px !important; */
        /* margin-left: 30px !important; */

     }
     .refer-section-text {
        text-align: center !important;
    }
    .refer-grey-one {
        margin-right: 0px !important;
    }
    .img-location-new {
        width: 300px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    .refer-section-fr-one::after {
        content: "|";
        font-size: 17px;
        padding: 0px 36px;
        position: absolute;
        color: #2C2C2C;
        opacity: 0.2;
    }
    .refer-section-fr-grey{
        margin-left: 10px;
        margin-right: 10px;
    }
    .refer-section-fr-btn {
        width: 342px !important;
        margin-left: 15px;
    }

}
@media only screen and (max-width: 375px) {
    .neworder-fr {
        margin-left: 25px !important;
     }
     .d-flex-fr {
         width: 100px;
         margin-left: 67px !important;
         margin-right: auto;
     }
     .ordersubrow {
        margin-left: 0px !important;
        margin-right: 0px !important;
     }
     .img-location-new {
        width: 300px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
     .ordermodebox {
         margin-top: 0px !important;
         /* margin-left: 30px !important; */
     }
     .container {
         padding-left: 0px !important;
         padding-right: 0px !important;
     }
     .refer-section-header {
        text-align: center !important;
    }
    .refer-section-text {
        text-align: center !important;
    }
     /* .ordertakeaway-fr {
         margin-top: 30px !important;
     } */
     .ordermain {
        padding: 0px !important;
        /* margin-right: 10px !important; */
        /* margin-left: 0px !important; */
     }
     .about-fr {
         margin-top: 16px !important;
     }
     .takeawayimgmobile{
        margin-top: -60px !important;
    }
    /* .dineinimgmobile {
        margin-top:-57px !important

    } */
    .refer-grey-one {
        margin-right: 0px !important;
    }
    .refer-section-fr-one::after {
        content: "|";
        font-size: 17px;
        padding: 0px 36px;
        position: absolute;
        color: #2C2C2C;
        opacity: 0.2;
    }
    .refer-grey-second {
        padding-left:2px !important;
    }
    .refer-section-fr-grey{
        margin-left: 10px;
        margin-right: 10px;
    }
    .refer-section-fr-second {
        width: 98px !important;
        
    }
    .refer-section-text {
        width: 331px !important;
    }
    .refer-section-fr-btn {
        width: 322px !important;
        margin-left: 8px;
    }
}
@media only screen and (max-width: 1168px) {
   
        .ordermodes-fr {
            background-image:url('../../../images/bannerdelivery.jpeg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
        .about-founder-text h3 {
            text-align: left;
            font: normal normal 600 12px Lato;
            letter-spacing: 0px;
            color: #553A28;
                margin: 0;
            }
            .about-founder-text p {
                margin: 0;
                color: #2C2C2C;
                padding-top:5px;
                font-size: 12px !important;
            
            }
        
          
}
@media (min-width: 768px) {
/* .ordermain {
    display: contents;

} */
.ordermodes-fr {
    background-image:url('../../../images/bannerdelivery.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
}
@media (max-width: 1300px) {
    .about-founder-text h3 {
        text-align: left;
        font: normal normal 600 12px Lato;
        letter-spacing: 0px;
        color: #553A28;
            margin: 0;
        }
}