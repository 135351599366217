.reward-root{
    display: flex; flex-direction: row;
    /* margin-left: 20px; margin-right: 20px;  */
    margin:10px;
    padding: 17px; border-radius: 6px;
    justify-content: space-between; min-height: 309px;
    /* background: transparent linear-gradient(266deg, #553A28 0%, #412A1B 100%) 0% 0% no-repeat padding-box; */
      background: linear-gradient(to right, #412A1B, #553A28);
      

}


.reward-header {
text-align: left;
color: #FFFFFF;
letter-spacing: 0px;
font: normal normal normal 35px/42px Lato;
text-transform: capitalize;
opacity: 1;
padding-top:20x;
padding-right: 0px !important;
}
.reward-header-mobile {
    text-align: left;
color: #FFFFFF;
letter-spacing: 0px;
font: normal normal normal 18px/42px Lato;
text-transform: capitalize;
opacity: 1;
padding-top:20x;
padding-right: 0px !important;
}

.reward-subheader {
    text-align: left;
padding-top:20x;
font: normal normal 600 35px/42px Lato;
letter-spacing: 0px;
color: #E5AB4E;
text-transform: capitalize;
}

.reward-image{
    position: absolute;
    right: 0px;
    top: 0px;
}


.reward-description {
    font-weight: lighter !important;
text-align: left;
font: normal normal normal 17px/16px Lato;
letter-spacing: 0px;
color: #FFFFFF !important;
text-transform: capitalize;
opacity: 1;
line-height: 21px !important;
padding-right: 43px;
  
}

.reward-btn {
   min-width: 270px;
    max-width: 292px;
    background-color: #E5AB4E !important;
    color: #2C2C2C !important;
  align-items: center;
     border-radius: 6px !important;
     height:50px;
border: none !important;
font: normal normal bold 20px/24px Lato;
font-size: 20px !important;
font-weight: bold !important;
line-height: 24px !important;
letter-spacing: 0px;
color: #2C2C2C !important;
text-transform: none !important;
opacity: 1;
text-align: start;
}
.reward-btn-image {
    float: right !important;
    margin-left: auto;
}
.reward-description-mobile {
    text-align: left;
    padding-top:20x;
    font: normal normal 400 14px/42px Lato;
    letter-spacing: 0px;
    color: #E5AB4E !important;
    text-transform: capitalize;
}
.reward-text {
    color:#FFFFFF !important;
    font-weight: 300 !important;
}
@media only screen and (max-width: 768px) {
.reward-image{
    position: absolute;
    right: 0px;
    top: 0px;
    height:100px;
}
.reward-header {
margin-top: 30px;

text-align: left;
color: #FFFFFF;
letter-spacing: 0px;
font: normal normal normal 35px/42px Lato;
text-transform: capitalize;
opacity: 1;
padding-top:20x;
padding-right: 0px !important;
}
}
@media only screen and (max-width: 768px) {
    .reward-btn {
        min-width: 258px;
    }


}