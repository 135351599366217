.emptycartpageimg {
    margin-top: 51px;
}
.emptyheader {
    margin-top: 20px;
    font-size: 24px;
    color: #4D3A21 !important;
    text-align: center !important;
    text-transform: none;
}
.emptycartpagetext {
    color:#2C2C2C !important;
    font-size: 16px !important;
    font-weight: normal !important;
    max-width: 343px;
    margin: auto !important;
    margin-top: 10px !important;
}
.emptycartpagebtn {
    border: none;
    background-color: #553a20;
    color:#fff;
    padding: 10px 30px;
    margin-top: 30px !important;
    border-radius: 6px !important;
    font-size: 13px !important;
    font-family: normal !important;
    text-transform: uppercase;
    letter-spacing: 1.2px;

}