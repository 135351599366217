.cart-footer-section {
	display: flex;
	flex-direction: column;
	margin: 0px 10px !important;
	padding: 10px 0px !important;
  }
  
  .add-text {
	  font-size: 12px !important;
  }
  .upi {
	  outline: auto !important;
  }
  .pay-method-button {
	  outline: auto !important;
	  margin-top: 20px !important;
	  margin-right: 10px !important;
  }

  .gapform {
	  margin-right: 10px !important;
  }
  .cart-loyalty-button {
	display: flex !important; 
	justify-content: space-between !important;
	flex: 1;
	border-radius: 0px !important;
	border-color: #F5F5F5 !important;
	outline: none !important;
  }
  
  .cart-loyalty-button > span:first-child {
	display: flex;
	align-items: center;
  }
  
  .cart-loyalty-button > span:first-child > img {
	margin-right: 5px;
	height: 36px;
	width: 36px;
  }
  
  
  .cart-loyalty-button:hover,
  .cart-loyalty-button:focus,
  .cart-loyalty-button:active {
	box-shadow: none;
	border-color: #F5F5F5 !important;
	outline: none !important;
	box-shadow: none !important;
  }
  
  .cart-loyalty-button:hover{
	background-color: #F5F5F5;
  }
  
  
  .cart-footer-section .table {
	margin: 0px 0px;
  }
  
  .cart-footer-section table > tbody > tr > td {
	// text-transform: uppercase;
	padding: 10px 0px !important;
  }
  
  .cart-footer-section table > tbody > tr > td:last-child {
	text-align: right;
  }
  
  .cart-footer-section table > tbody > tr.total-row > td {
	letter-spacing: 1.1px;
  }
  
  //checkout payment css
  
  .payment-cards-wrap {
  
  }
  .mt-5, .my-5 {
    
    border: none !important;
}
.pl-5{
    padding-left: 0px !important;
}
  .pay-method-button,
  .saved-payment-card,
  .add-new-card-button {
	display: flex;
	padding: 14px 17px;
	border: 1px solid #F5F5F5;
	border-radius: 4px;
	flex-direction: row;
	align-items: center;
  }
  
  .add-new-card-button {
	  margin-top: 20px !important;
  }
  .saved-payment-card {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	align-items: center;
  }
  
  .saved-payment-card > .saved-card-name {
	display: flex;
	align-items: center;
  }
  
  .pay-method-button:hover,
  .saved-payment-card:hover,
  .add-new-card-button:hover {
	border-color: #d0d0d0;
	cursor: pointer;
  }
  
  
  .pay-method-button:active,
  .saved-payment-card:active,
  .add-new-card-button:active,
  .pay-method-button:focus,
  .saved-payment-card:focus,
  .add-new-card-button:focus {
	outline: none;
  }
  
  .pay-method-button {
	min-height: 55px;
	min-width: 66px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: transparent;
	padding: 5px 15px;
	flex: 1;
	margin: 0px;
	border: 1px solid transparent;
	border-bottom: 1px solid #F5F5F5;
  }
  
  .pay-method-button.active {
	border-color: #ddd;
  }
  
  .pay-method-button img {
	height: 25px;
  }
  
  .netbanking-wrap,
  .upi-wrap {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
  }
  
  
  .netbanking-wrap .select,
  .netbanking-wrap select {
	width: 100% !important;
  }
  
  .pay-method-button .pmb-name {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
  }
  
  .pay-method-button .pmb-name label {
	margin-left: 10px;
	white-space: nowrap;
  }
  
  .field {
	  margin-top: 10px;
  }
  .label {
	  font-size: 14px !important;
	  letter-spacing: 2.1px !important;
	  color:#2C2C2C !important;
	  font-weight: bold !important;
	}
  // Extra small devices (portrait phones, less than 576px)
  // No media query since this is the default in Bootstrap
  
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
  
  }
  
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
	
  }
  
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
	.netbanking-wrap,
	.upi-wrap {
	    flex-direction: row;
	}
	
	.pay-method-button {
	    border: 1px solid #F5F5F5;
	    min-height: 66px;
	    justify-content: center;
	    background: transparent;
	    padding: 5px;
	    flex: 1;
	    margin-left: 10px;
	    margin-bottom: 10px;
	}
  
	.pay-method-button {
	    flex-direction: column;
	}
  
	.pay-method-button .pmb-name {
	    flex-direction: column;
	}
  
	.pay-method-button .pmb-name label {
	    margin-left: 0px;
	    margin-top: 8px !important;
	}
  }
  
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
  
  }
  