.hotFavourites {
/* margin-left: 5px; */
margin-top: 40px;
margin-bottom: 40px;
}
.imgpad2 {
  width: 281px;
  height:max-content;
  margin-right: 22px;
  border-radius: 6px;
  opacity: 1;
}

 .imgpad2 img {
  width: 281px;
  height:337px;
  object-fit: cover;
    border-radius: 6px;
    opacity: 1;
}

.favname {
  color: #553A28;
  font-size: 18px;
  font-weight: 600;
  margin-top: -5px;
    margin-left: 5px;
  text-align: left;
  padding-right: 100px;
}

.favprice {
  margin-top: 7px;
  margin-left: 5px;
  font-weight: 600;
}

.itemDetails {
  display: flex;
  margin-right: 0 !important;
  margin-top: 10px;
  flex-direction: row;
  /* justify-content: space-between; */
}
