.settingaccountrow {
    padding-top: 42px;
    margin-left:18px;
    margin-right: 24px;
}
.fa-location-arrow,
.fa-paypal,
.fa-money,
.fa-link
{
    height:20px;
    width: 20px;
    opacity: 100%;
    color:#4D3A21;
    margin-right:5px;
}
.accountname {
    color:#2C2C2C;
    font-size: 18px;
    font-weight:500;
    line-height: 22px;
}
.accountname i {
    color:lightgrey !important;
    height: 19px;
    width:19px;
    font-size: 20px;
    font-weight: 500;
    float: right;
}