.enquiryModel {
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    margin-top:42px;
    margin-bottom:51px;
}
.enquiryModel h2 {
text-align: center;
font: normal normal 600 20px/34px Lato;
letter-spacing: 2px;
color: #553A28;
text-transform: uppercase;
opacity: 1;
}
.enquiryModel p {
text-align: center;
font: normal normal normal 16px/24px Lato;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
margin:0px;
}
.FranchiseEnquiry {
    padding: 44px;
    padding-right: 0px !important   ;
    padding-bottom: 0px !important;
    background: #fff;
    box-shadow: 0px 0px 20px #0000000D;
    border-radius: 6px;
    margin: 0px;
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.FranchiseEnquiry img {
    top: 454px;
left: 790px;
width: 419px;
height: 573px !important;
border-radius: 4px;
opacity: 1;
    width: 100%;
    border-radius: 4px;
    padding-right:51px;
    padding-bottom: 10px;
}
.formSection-fr {
    padding-bottom: 53px !important;
}
.formSection h2 {
text-align: left;
font: normal normal medium 25px/30px Lato;
letter-spacing: 0px;
color: #553A28;
opacity: 1;
text-transform: none !important;
}
.formSection p {
    height:18px;
text-align: left;
font: normal normal normal 15px/14px Lato;
letter-spacing: 0px;
color: #2C2C2C;
opacity: 1;
margin-bottom: 0px;
}
.formSection {
    margin-left:51px;
}
.formSection .row {
    margin-top: 25px;
}

.formSection input.form-control,
.formSection select.form-control {
width: 316px;
background: #F0F1F2 0% 0% no-repeat padding-box;
border-radius: 6px;
opacity: 1;
    border: none;
    height: 45px;
}

.formSection textarea.form-control {
    background: #F0F1F2;
    border: none;
    border-radius: 6px;
    min-height: 92px;
}
.formSection .primaryBtn,
.formSection .secondaryBtn {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    
}
.franch {
    margin-bottom: 27px;
    margin-top:120px;
}
.formSection .primaryBtn {
text-align: center;
font: normal normal bold 14px/20px Lato;
letter-spacing: 1.4px;
color: #FFFFFF;
opacity: 1;
    background: linear-gradient(to right, #3B291D, #553A28);
    border: none;
}
.formSection .secondaryBtn {
    background: #fff;
    border: dashed 2px #553A28;
    color: #553A28;
    margin-top:0px !important;
    text-align: center;
}
.formSection .secondaryBtn img {
    width: 15px;
    margin-right: 10px;
    margin-top: 0;
}


@media only screen and (max-width: 767px) {
    .enquiryModel {
        max-width: 100%;
        margin: 50px auto;
    }
    .FranchiseEnquiry {
        padding: 15px;
        padding-top:30px !important;
    }
    .FranchiseEnquiry img {
        margin-top: 2rem;
        padding-right: 0px !important;
    }
    .formSection h2 {
        text-align: center !important;
        text-transform: none !important;
    }
    .formSection .row {
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media only screen and (max-width: 768px) {
    .formSection .secondaryBtn  {
        font-size: 10px !important;
    }
    .submitnew {
        margin-top: 0px !important;
    }
    .modelForm-fr {
        margin-top: 20px !important;
    }
}