.ourStory {
    background: #FAFBFC;
    padding: 50px 0;
    margin-top:90px;
}
.storyHeading {
    position: relative;
}
.storyHeading h3 {
margin-top: 39px;
text-align: left;
font: normal normal 500 30px/40px Lato;
letter-spacing: 0px;
color: #553A28;
}
.form1 {
    margin-right:0px !important;
}
.formp {
    color:#FFFFFF !important;
    text-align: center;
    padding-top: 10px !important;
}

.storyHeading img {
    position: absolute;
    top: 0;
}
.storyHeading img:last-child {
    position: absolute;
    bottom: 0;
    right: 10px;
    left: auto;
    top: auto;
}
.storyImg {
background: transparent linear-gradient(162deg, #FFD48E 2, #FADAA7 100%, #FADAA7 100%) 100% 0% no-repeat padding-box;
border-radius: 4px;
opacity: 1;
    position: relative;
    z-index: 9;
}
.storyImg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50px;
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    background: #FFD48E;
    z-index: -1;
}
.storyImg img {
    margin-top: 40px;
    width: calc(100% - 40px);
}
.storyList ul {
    list-style: none;
    padding-left: 0;
    margin-top: 30px;
}
.storyList ul h4 {
    font-size: 22px;
    margin-bottom: 8px;
}
.storyList ul li {
    position: relative;
    margin-bottom: 30px;
}
.storyList ul li::before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 24px);
    border-left: dashed 2px #E5AB4E;
    left: -21px;
    top: 12px;
}
.storyList ul li:last-child::before {
    display: none;
}
.storyList ul li h4::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: #E5AB4E;
    left: -27px;
    top: 5px;
    border-radius: 50%;
    box-shadow: 0 0 1px 3px rgba(229, 171, 78, 0.2);
}
.storyList ul li p {
    font-size: 14px;
    margin: 0;
}

@media screen and (max-width:767px) {
    .storyList {
        padding-left: 25px;
    }
}