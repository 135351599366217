.spinmain {
   
    background-color:#FFFFFF;
    text-align: center;
}
.spinhead {
    color:#553A28;
    font-size:24px;
    line-height:29px;
    text-align:center;
    font-weight: 400;
}
.spinheader {
    padding-top:30px;
    padding-bottom: 80px;
}
.spinheader i {
    color:#4D3A21;
    float: left;
    margin-bottom: 0px !important;
    width:31px;
    height:31px;
    margin-top:0px !important;
}
.spinbtn {
    background-color:#553A28 ;
    color:#FFFFFF;
    font-size: 14px;
    font-weight: bold;
    padding-top:15px;
    padding-bottom: 15px;
    padding-right: 150px;
    padding-left:150px;
    letter-spacing: 1.4px;
    margin-top:80px;
    margin-bottom: 27px;
}
.spinmain img {
    vertical-align: middle;
    padding-left:10px;
}
.spinmain p {
    color:#2C2C2C;
    font-size: 15px;
    text-align: center;
}
