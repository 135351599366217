
.snackbar {
  align-self: center;
  visibility: hidden;
  /* min-width: 250px; */
  width: 360px;
  margin-left: -180px;
  background-color: #2C2C2C;
  color: #fff;
  text-align: center;
  /* padding: 16px; */
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 15px;
  font-size: 1rem;
  border-radius: 7px;
  align-items: flex-start;
  box-shadow: 0px 7px 20px 5px rgba(0,0,0,0.27);
}

.snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
 However, delay the fade out process for 2.5 seconds */
-webkit-animation: fadein 0.2s,
fadeout 0.5s 3s;
animation: fadein 0.2s,
fadeout 0.5s 3s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 15px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 15px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 15px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 15px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}