.modal-backdrop {
  background-color: #2C2C2C;
}
.modal-backdrop.show {
  opacity: .8;
}
 
.modal.show {
  display: flex;
  align-items: center;
  max-width: 310px;
}
.modal-dialog {
  display: flex;
  align-items: center;
  max-width: 400px;
  margin:80px auto;
}
.text-couponinvalid {
  font-size: 15px !important;
}
.couponmodal {
  height: 450px !important;
}

.couponinput {
  border-radius: 5%;
  background-color: #F0F1F2;
} 
.newcouponbox {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 7px 4px #ECEEF4C4;
  margin-top: 30px;
  display: flex;
  overflow: hidden;
  margin:10px;
  margin-bottom: 20px;
} 
.couponhead {
  padding-top:26px;
  text-align: center;
  font: normal normal 600 30px/20px Lato;
  letter-spacing: 0px;
  color: #553A28;
  opacity: 1;
}
.couponpara {
  margin-top: 10px;
  text-align: center;
  font: normal normal normal 12px/18px Lato;
  letter-spacing: 0px;
  color: #2C2C2C;
  opacity: 1;
}
 
.coupona { 
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: #26B383 !important;
  border: 1px dashed #138E64;
  border-radius: 4px;
  float: left;
  width: fit-content;
  
  height: fit-content;
}
 
.couponbtn {
  /* float:right; */
  background: #E5AB4E 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 20px;
  padding-right:20px;
  text-align: center;
  font: normal normal 600 14px Lato;
  letter-spacing: 1.4px;
  color: #FFFFFF !important;
  text-transform: uppercase;
  opacity: 1;
  height: fit-content;
}

 
.couponh {
  text-align: left;
    font: normal normal 600 16px/20px Lato;
    letter-spacing: 0px;
    color: #2C2C2C;
    opacity: 1;
    padding-top: 6px;
}
.couponp {
  margin-top:4px;
  text-align: left;
  letter-spacing: 0px;
  color: #2C2C2C;
  opacity: 1;
}
 
.couponp p {
  font-size: 12px !important;
  text-align: left !important;
  margin: 0px !important;
} 
.couponview {
margin-top:13px;
height: 16px;
opacity: 1;
color:#E5AB4E !important;
font-size: 13px;
letter-spacing: 1.3px;
padding-left: 10px;
text-align:left;
}
.termscouponnew ul{
  font-size: 12px !important;
  text-align: left;
  margin-left: 10px !important;
}
   

.cpn-input{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 10px;
  margin-inline-start: 10px;
   font-size: 18px;
  font-family: 'Open Sans';
  height: 32px;
  width: -webkit-fill-available;
  text-align: center;
  background: #F0F1F2 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border-width: 0px;
  margin-top: 10;
}

.cpn-custom-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 15px;
  width: -webkit-fill-available;
}

.successBg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.success-cpn {
  color: #00A56D;
  font-size: 24px;
  font-weight: 600;
  margin: 15px 0 5px;
}
.success-cpn-text {
  color: #2C2C2C;
  font-size: 20px;
  opacity: 0.55;
}