.store-root{
	display: flex;
	flex-direction: 'column';
    /* width: 500; */
        height: '30vh';
	/* height:600px; */
	/* align-items: inherit; */
	width: -webkit-fill-available;
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width:none;
	flex-wrap: nowrap;
	scroll-behavior: smooth;
	border-bottom: 2px solid #f7f7f7ec;
	margin:5px;
	/* background-color: rgba(192,192,192, 0.2); */
  }
  
  .store-root::-webkit-scrollbar{
  display: none;
  }
  
  .store-maincard{
	  display: flex;
	flex: 0 0 auto;
	flex-direction: row;
	justify-content: space-between;
	width: 37vw;
	 height: 80px;
	 padding: 10px;
	margin: 15px; 
	border-bottom: 2px solid #f7f7f7ec;

   cursor: 'pointer';
 height: 'fit-content';
       align-items: 'flex-start';
        border-bottom: '1px solid #F7F7F7'
	/* background-color: rgba(192,192,192, 0.2);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px; */
  
  }